import { 
  GET_ALL,
  GET_ALL_LOADING,
  GET_ALL_ERROR,

  POST_BY_ENTITY,
  POST_BY_ENTITY_LOADING,
  POST_BY_ENTITY_ERROR,

  PATCH_BY_ENTITY,
  PATCH_BY_ENTITY_LOADING,
  PATCH_BY_ENTITY_ERROR,

  GET_BY_ID,
  GET_BY_ID_LOADING,
  GET_BY_ID_ERROR,

  PUT_BY_ID,
  PUT_BY_ID_LOADING,
  PUT_BY_ID_ERROR,

  DELETE_BY_ID,
  DELETE_BY_ID_LOADING,
  DELETE_BY_ID_ERROR,
  SET_UNIQUE_CUSTOMERS,
  GET_CUSTOMER_ALERTS_BY_ID,
  GET_CUSTOMER_ALERTS_BY_ID_ERROR,
} from '@/shared/store/actionType';
import { httpRequest, updateBreadcrumb } from '@sales-i/utils';
import querystring from 'querystring';

// initial state
const state = () => ({
  data: [],
  queries: [],
  errors: [],
  uniqueCustomers: [],
  loading: false,
  loaded: false,
  customerAlerts: {}
});

// getters
const getters = {};

// mutations
const mutations = {
  [GET_ALL]: (state, data) => {
    state.loading = false;
    state.loaded = true;
    state.errors = [];
    state.queries = data;
  },
  [GET_ALL_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.errors = [];
  },
  [GET_ALL_ERROR]: (state, errors) => {
    state.loading = true;
    state.loaded = false;
    state.errors = errors;
    state.queries = [];
  },

  [GET_BY_ID]: (state, data) => {
    state.loading = false;
    state.loaded = true;
    state.errors = [];
    state.data = data;
  },
  [GET_BY_ID_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.errors = [];
    state.data = {};
  },
  [GET_BY_ID_ERROR]: (state, errors) => {
    state.loading = true;
    state.loaded = false;
    state.errors = errors;
    state.data = {};
  },

  [POST_BY_ENTITY]: (state) => {
    state.loading = false;
    state.loaded = true;
    state.errors = [];
  },
  [POST_BY_ENTITY_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.errors = [];
  },
  [POST_BY_ENTITY_ERROR]: (state, errors) => {
    state.loading = false;
    state.loaded = true;
    state.errors = errors;
  },

  [PATCH_BY_ENTITY]: (state, results) => {
    // Check if state.data.results is an array before trying to find the index 
    if (!Array.isArray(state.data.results)) {
      state.data.results = [];
    }
    const idx = state.data.results.findIndex(o => o.id === results.id);
    
    if (idx !== -1) {
      state.data.results[idx] = results;
      state.loading = false;
      state.loaded = true;
    }
  },
  [PATCH_BY_ENTITY_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.errors = [];
  },
  [PATCH_BY_ENTITY_ERROR]: (state, errors) => {
    state.loading = false;
    state.loaded = true;
    state.errors = errors;
  },

  [PUT_BY_ID]: (state, results) => {
    state.data.results.push(results[0]);
    state.loading = false;
    state.loaded = true;
    state.errors = [];
  },
  [PUT_BY_ID_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.errors = [];
  },
  [PUT_BY_ID_ERROR]: (state, errors) => {
    state.loading = true;
    state.loaded = false;
    state.errors = errors;
  },

  [DELETE_BY_ID]: (state) => {
    state.loading = false;
    state.loaded = true;
    state.errors = [];
  },
  [DELETE_BY_ID_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.errors = [];
  },
  [DELETE_BY_ID_ERROR]: (state, errors) => {
    state.loading = true;
    state.loaded = false;
    state.errors = errors;
  },

  'UPDATE_QUERY_FIELD': (state, [field, value]) => {
    state.data[field] = value;
  },
  'UPDATE_QUERY': (state) => {
    state.loading = false;
    state.loaded = true;
    state.errors = [];
  },
  'UPDATE_QUERY_LOADING': (state) => {
    state.loading = true;
    state.loaded = false;
    state.errors = [];
  },
  'UPDATE_QUERY_ERROR': (state, e) => {
    state.loading = false;
    state.loaded = true;
    state.errors = e;
  },
  [SET_UNIQUE_CUSTOMERS]: (state, customers) => {
    state.uniqueCustomers = customers;
  },
  [GET_CUSTOMER_ALERTS_BY_ID]: (state, data) => {
    state.loading = false;
    state.loaded = true;
    state.errors = [];
    state.customerAlerts = data;
  },
  [GET_CUSTOMER_ALERTS_BY_ID_ERROR]: (state, e) => {
    state.loading = false;
    state.loaded = true;
    state.errors = e;
  },
};

// actions
const actions = {
  [GET_ALL]: async({ commit }, parameters = { sort: 'id:desc' }) => {
    commit(GET_ALL_LOADING);
    try {
      const params = querystring.stringify(parameters);
      
      const response = await httpRequest('get', `saved-query?${params}`, {}, {
        version: '2',
        area: 'enquiries',
      });
      commit(GET_ALL, response);
    } catch (e) {
      commit(GET_ALL_ERROR, [e]);
      console.error(e);
    }
  },
  [GET_BY_ID]: async({ commit }, id) => {
    try {
      commit(GET_BY_ID_LOADING);
      const response = await httpRequest('get', `saved-query/${id}`, {}, {
        version: '2',
        area: 'enquiries',
      });
      updateBreadcrumb('alerts', id, `${response?.query_name?.split('|~~|')[0]}`);
      commit(GET_BY_ID, response);
    } catch (e) {
      commit(GET_BY_ID_ERROR, [e]);
      console.error(e);
    }
  },

  [POST_BY_ENTITY]: async ({ commit }, query) => {
    try {
      commit(POST_BY_ENTITY_LOADING);
      await httpRequest('post', 'saved-query', {
        ...query,
      }, {
        version: '2',
        area: 'enquiries',
      });

      commit(POST_BY_ENTITY);
    } catch (e) {
      commit(POST_BY_ENTITY_ERROR, [e]);
      console.error(e);
    }
  },

  'UPDATE_QUERY': async({ commit }, query) => {
    try {
      commit('UPDATE_QUERY_LOADING');
      await httpRequest('put', 'saved-query', {
        ...query,
      }, {
        version: '2',
        area: 'enquiries',
      });

      commit('UPDATE_QUERY');
    } catch (e) {
      commit('UPDATE_QUERY_ERROR', [e]);
      console.error(e);
    }
  },

  [PUT_BY_ID]: async({ commit }, [entityType, entityId, query]) => {
    try {
      commit(PUT_BY_ID_LOADING);
      const response = await httpRequest('post', `saved-query/${query.id}`, {
        entity_id: entityId,
        entity_type: entityType,
        ...query,
      }, {
        version: '2',
        area: 'enquiries',
      });

      commit(PUT_BY_ID, response);
    } catch (e) {
      commit(PUT_BY_ID_ERROR, [e]);
      console.error(e);
    }
  },

  [PATCH_BY_ENTITY]: async({ commit }, [queryId, results]) => {
    try {
      commit(PATCH_BY_ENTITY_LOADING);
      await httpRequest('put', `saved-query/${queryId}/${results.id}`, {
        ...results,
      }, {
        version: '2',
        area: 'enquiries',
      });

      results.created_date = Date.now();

      commit(PATCH_BY_ENTITY, results);
    } catch (e) {
      commit(PATCH_BY_ENTITY_ERROR, [e]);
      console.error(e);
    }
  },

  [DELETE_BY_ID]: async({ commit }, id) => {
    try {
      commit(DELETE_BY_ID_LOADING);
      await httpRequest('delete', `saved-query/${id}`, {}, {
        version: '2',
        area: 'enquiries',
      });
      commit(DELETE_BY_ID);
    } catch (e) {
      commit(DELETE_BY_ID_ERROR, [e]);
      console.error(e);
    }
  },

  'UPDATE_QUERY_FIELD': ({ commit }, parameters) => {
    commit('UPDATE_QUERY_FIELD', parameters);
  },
  [GET_CUSTOMER_ALERTS_BY_ID]: async({ commit }, { id, children }) => {
    commit(GET_BY_ID_LOADING);
    try {
      const queryParams = new URLSearchParams();
      if (Array.isArray(children))
        children.forEach(child => queryParams.append('children', child));

      const response = await httpRequest('get', `saved-query/customer/${id}?${queryParams}`, {}, {
        area: 'enquiries',
      });
      commit(GET_CUSTOMER_ALERTS_BY_ID, response);
    } catch (e) {
      commit(GET_CUSTOMER_ALERTS_BY_ID_ERROR, e);
      console.error(e);
    }
  },
  [SET_UNIQUE_CUSTOMERS]: ({ commit }, customers) => {
    commit(SET_UNIQUE_CUSTOMERS, customers);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
