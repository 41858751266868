<template>
  <div class="container flow">
    <FilterHeader
      :action-text="actionText"
      :date-filter="dateFilter"
    />
    <FilterSummary />
    
    <div class="container flow">
      <div class="flex">
        <FilterClauseSwitch 
          v-if="!isFirstCrmSection"
          :selected-value="crmDataClause"
          @switch-selected="handleSwitch"
        />
      </div>
      
      <template
        v-for="(filter, index) in filterClauses"
        :key="filter.id"
      >
        <section class="flow">
          <div class="flex">
            <FilterClauseSwitch 
              v-if="index !== 0 && filter.entity !== 'interaction'"
              class="mt-4"
              :selected-value="filter.clause"
              @switch-selected="handleFilterSwitch(filter, $event)"
            />
          </div>
          
          <EntitySelector 
            v-if="filter.field !== 'date_end'"
            :id="`area-${filter.id}`"
            :value="filter.entity"
            :disabled="filter.field === 'interaction_type'"
            @input="setEntity(filter, $event)"
          />
          
          <InteractionTypeFilter
            v-if="filter.field === 'interaction_type'"
            :filter="filter"
            @update:filter="updateFilter"
          />

          <InteractionPeriodFilter
            v-if="filter.field === 'date_end'"
            :filter="filter"
            @update:filter="updateFilter"
          />
          
          <ContactFilter 
            v-if="filter.entity === 'contact'"
            :filter="filter"
            @update:filter="updateFilter"
          />
          
          <LocationFilter 
            v-if="filter.entity === 'address'"
            :filter="filter"
            @update:filter="updateFilter"
          />

          <CustomSectionFilter
            v-if="filter.entity && filter.entity.includes('customsection')"
            :filter="filter"
            @update:filter="updateFilter"
          />

          <IconButton
            v-if="filter.entity !== 'interaction'"
            icon-name="trash"
            purpose="transparent"
            icon-color="var(--colour-utility-action)"
            variant="round"
            :icon-size="28"
            @on-click="removeCustomerFilterWithConfirmation(filter)"
          />
        </section>
      </template>
      
      <AddFilterButton @add-filter="handleAddButtonClick" />
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, watchEffect } from 'vue';
import { useStore } from 'vuex';
import FilterHeader from './FilterHeader.vue';
import FilterSummary from '@/intelligence/components/AlertBuilder/AlertWizard/FilterSummary.vue';
import FilterClauseSwitch from './FilterClauseSwitch.vue';
import EntitySelector from './EntitySelector.vue';
import ContactFilter from './ContactFilter.vue';
import LocationFilter from './LocationFilter.vue';
import AddFilterButton from './AddFilterButton.vue';
import { IconButton } from '@sales-i/dsv3';
import { tCrm as t } from '@sales-i/utils';
import { SET_CONFIRMATION_MODAL } from '@/shared/store/actionType';
import { useCustomerFilters } from '@/intelligence/composables/useCustomerFilters';
import useFields from '@/shared/composables/useFields';
import InteractionTypeFilter from './InteractionTypeFilter.vue';
import InteractionPeriodFilter from './InteractionPeriodFilter.vue';
import CustomSectionFilter from './CustomSectionFilter.vue';
import { CRM_DATA_CLAUSE } from '@/intelligence/store/actionType';

const store = useStore();

defineProps({
  actionText: {
    type: String,
    default: '',
  },
  dateFilter: {
    type: String,
    default: '',
  },
  isFirstCrmSection: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['valid']);

const { getFields } = useFields({ store });
const { filterClauses, isValid, updateFilter, removeFilter } = useCustomerFilters({ store });

watchEffect(() => {
  emit('valid', isValid.value);
});

onBeforeMount(async () => {
  await getFields({ entity: 'interactions' });
  // set initial clauses for new filter
  if (filterClauses.value.length === 0) {
    updateFilter(defaultClauses.value.interaction[0]);
    updateFilter(defaultClauses.value.interaction[1]);
  }
});

const crmDataClause = computed(() => store.state.intelligence.enquiry.crmDataClause);

const defaultClauses = computed(() => ({
  interaction: [
    {
      entity: 'interaction',
      field: 'interaction_type',
      is: '=',
      value: '',
      clause: 'AND',
    },
    {
      entity: 'interaction',
      field: 'date_end',
      is: '',
      value: '',
      clause: 'AND',
    },
  ],
  contact: [{
    entity: 'contact',
    field: 'job_title',
    is: '=',
    value: '',
    clause: 'AND',
  }],
  address: [{
    entity: 'address',
    field: '',
    is: '=',
    value: '',
    clause: 'AND',
  }],
  customsection: [{
    entity: 'customsection',
    field: '',
    is: '',
    value: '',
    clause: 'AND',
  }],
}));

// set entity reset current filter
function setEntity(filter, value) {
  defaultClauses.value[value].forEach((clause) => updateFilter({ ...filter, ...clause }));
}

function handleAddButtonClick(type) {
  updateFilter({
    clause: type || 'AND',
    entity: '',
    field: '',
    is: '',
    value: '',
  });
}

async function removeCustomerFilterWithConfirmation({ id, entity, field }) {
  store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, {
    message: t('do_you_really_want_to_delete_this_filter'),
    updatedMessage: t('You have deleted this filter', 'you_have_deleted_this_filter'),
    updateFunction: async () => await removeFilter({ id, entity, field })
  });
}

function handleFilterSwitch(filter, selectedValue) {
  updateFilter({
    id: filter.id,
    clause: selectedValue.value,
  });
}

function handleSwitch(event) {
  store.dispatch(`intelligence/enquiry/${CRM_DATA_CLAUSE}`, event.value);
}
</script>

<style lang="scss" scoped>
.subtitle {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
}

h3 {
  font-weight: var(--font-weight-semibold);
  display: flex;
  flex-wrap: wrap;
}
</style>