<template>
  <CustomButton
    v-if="props.small && permissions.create"
    class="add-btn-small"
    icon-name="plus"
    icon-color="var(--colour-utility-white)"
    icon-only
    @click="onAddClick"
    @on-close="handleCancel"
  />
  <p class="heading text-4 fw-sbld px-3 flex">
    <span>{{ t('opportunities') }}</span>
    <span class="total-oppo">{{ totalOppAmount }}</span>
    <template v-if="item.opportunities.length">
      <CustomButton
        v-if="attachedOpps.length && !isSearchMode"
        type="button"
        purpose="text"
        @click="handleAttachMore"
      >
        {{ t('edit') }}
      </CustomButton>
    </template>
  </p>

  <CustomCard
    v-if="permissions.read && (isSearchMode || attachedOpps.length)"
    purpose="reversed"
    footer-color="grey"
    class="opportunities"
    :class="{ 'search-active' : isSearchMode }"
    small
  >
    <div
      v-if="!isSearchMode"
      class="stack-wrap flow"
      :class="{ 'max-w-25': !props.small, 'px-3': item.opportunities.length === 1}"
    >
      <template v-if="item.opportunities.length > 1">
        <Carousel
          class="carousel"
          show-dots
          :slides-amount="item.opportunities.length"
          :active-slide-index="activeSlideIndex"
          @update:active-slide-index="activeSlideIndex = $event"
        >
          <CarouselSlide
            v-for="(itemOpp, index) in item.opportunities"
            :key="itemOpp.id"
            :is-active="index === activeSlideIndex"
            class="carousel-slide"
          >
            <OpportunityCard
              :item="itemOpp"
              :links-enabled="[]"
              :show-tags="false"
              class="opp-card"
              @remove="handleRemoveOpportunity"
            />
          </CarouselSlide>
        </Carousel>
      </template>
      <template v-else>
        <OpportunityCard
          v-for="itemOpp in item.opportunities"
          :key="itemOpp.id"
          :item="itemOpp"
          :links-enabled="[]"
          :show-tags="false"
          class="opp-card"
          @remove="handleRemoveOpportunity"
        />
      </template>
    </div>

    <div v-else>
      <div class="search-wrapper">
        <SearchInput
          :value="searchValue"
          :disabled="opportunities.loading"
          class="mb-0"
          @search="handleSearch"
        />
      </div>

      <BufferImage
        v-if="opportunities.loading"
        float="center"
        :size="40"
      />
      <div
        v-else
        class="stack-wrap flow"
        :class="{ 'max-w-25': !props.small }"
      >
        <SelectableItem
          v-for="(opp, oppIndex) in opportunities.data"
          :id="parseInt(opp.id, 10)"
          :key="oppIndex"
          :is-selected="isSelected(opp)"
          @itemselected="onItemClick(opp)"
        >
          <OpportunityCard
            :links-enabled="getOpportunityCardLinks(opp)"
            :item="opp"
            :clickable="false"
            :show-tags="false"
            class="selectable-card"
          />
        </SelectableItem>
      </div>
      <ButtonGroup
        v-if="!opportunities.loading"
        class="search-actions"
      >
        <CustomButton
          v-if="permissions.create && isSearchMode && props.small"
          type="button"
          small
          purpose="reversed"
          @click="closeSearchMode"
        >
          {{ t('cancel') }}
        </CustomButton>
        <CustomButton
          v-if="permissions.create && isSearchMode && props.small"
          type="button"
          small
          purpose="action"
          :disabled="selected.loading || !selected.loaded || !refreshNeeded"
          @click="handleAttachSelected"
        >
          {{ t('link') }}
        </CustomButton>
      </ButtonGroup>
    </div>
  </CustomCard>

  <div
    v-if="!isSearchMode && !item.opportunities.length"
    class="add-opportunities"
  >
    <p>
      {{ t('link_relevant_opportunities_here_to_add_sales_potential_to_this_interaction_and_log_event_progress_for_those_opportunities') }}
    </p>
    <CustomButton
      type="button"
      purpose="text"
      class="add-opportunities__btn text-center"
      @click="handleAttachMore"
    >
      {{ t('name_opportunities', entityName) }}
    </CustomButton>
    <CustomButton
      v-if="!props.small && permissions.create"
      type="button"
      purpose="text"
      class="mt-2"
      @click="onAddClick"
      @on-close="handleCancel"
    >
      {{ t('add_new_opportunity') }}
    </CustomButton>
  </div>

  <Teleport to="#modal-teleport-target">
    <AddEditOpportunityModal
      v-if="isAddEditOpportunityModal"
      :key="opportunityModalKey"
      :entity-id="currentlyEditing.entity.id"
      :entity-type="currentlyEditing.entityType"
      :entity-name="currentlyEditing.entity.name"
      :entity-preselected="!!currentlyEditing.entity.id"
      @close="toggleOpportunityModal"
      @saved="onOpportunitySaved"
    />
  </Teleport>
</template>

<script setup>
import { computed, nextTick, ref } from 'vue';
import { tCrm as t } from '@sales-i/utils';
import { CustomCard, CustomButton, BufferImage } from '@sales-i/dsv3';
import OpportunityCard from '@/crm/components/Opportunity/OpportunityCard.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { opportunities as opps } from '@/shared/store/data/policies';
import { useStore } from 'vuex';
import SearchInput from '@/dsv3/Forms/SearchInput.vue';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { useRoute } from 'vue-router';
import { useOpportunity } from '@/crm/composables/useOpportunity';
import { useInteraction } from '@/crm/composables/useInteraction';
import useCurrency from '@/shared/composables/useCurrency';
import SelectableItem from '@/crm/components/Calendar/SelectableItem.vue';
import AddEditOpportunityModal from '@/crm/components/Opportunity/AddEditOpportunityModal.vue';
import Carousel from '@/shared/components/Carousel/Carousel.vue';
import CarouselSlide from '@/shared/components/Carousel/CarouselSlide.vue';

const { getPermissions } = usePermissions();
const permissions = getPermissions(opps);
const { cPrice } = useCurrency();

const store = useStore();
const vroute = useRoute();

const { opportunities, getOpportunities } = useOpportunity({ store });
const {
  currentlyEditing,
  item,
  selected,
  setOpportunity,
  removeOpportunity,
  putInteraction,
  getInteraction,
} = useInteraction({ store, vroute });

const searchValue = ref(undefined);
const isSearchMode = ref(false);
const refreshNeeded = ref(false);
const isAddEditOpportunityModal = ref(false);
const reactiveItem = ref(item);
const activeSlideIndex = ref(0);

const props = defineProps({
  small: {
    type: Boolean,
    default: false,
  },
});

const attachedOpps = computed(() => currentlyEditing.value.opportunities);
const entityName = computed(() => currentlyEditing.value.entity.name);
const selectedOpportunity = computed(() => store.state.crm.opportunities.selected.data);
const id = computed(() => selected.value.data?.id);
const totalOppAmount = computed(() =>
  reactiveItem.value.opportunities
    ? cPrice(reactiveItem.value.opportunities.reduce((total, opp) => total + opp.value, 0))
    : 0
);

async function handleAttachSelected() {
  await putInteraction({ id: id.value });
  nextTick(() => getInteraction({ id: id.value }));
  refreshNeeded.value = true;
}

function closeSearchMode() {
  isSearchMode.value = false;
  getInteraction({ id: id.value });
}

function handleSearch(value) {
  searchValue.value = value;
  nextTick(() =>
    getOpportunities({
      q: value,
      entity_id: currentlyEditing.value.entity.id,
    })
  );
}

function handleAttachMore() {
  isSearchMode.value = true;
  handleSearch(searchValue.value);
}

async function handleRemoveOpportunity(opportunity) {
  if (isSelected(opportunity)) {
    await removeOpportunity({ opportunity });
    await putInteraction({ id: id.value });
    nextTick(() => getInteraction({ id: id.value }));
  }
}

function isSelected(opportunity) {
  return attachedOpps.value.findIndex(o => parseInt(o.id, 10) === parseInt(opportunity.id, 10)) >= 0;
}

async function onItemClick(opportunity) {
  refreshNeeded.value = true;
  if (isSelected(opportunity)) removeOpportunity({ opportunity });
  else setOpportunity({ opportunity });
}

function getOpportunityCardLinks() {
  return [];
}

function onAddClick() {
  isAddEditOpportunityModal.value = true;
}
function toggleOpportunityModal() {
  isAddEditOpportunityModal.value = !isAddEditOpportunityModal.value;
}
async function onOpportunitySaved() {
  setOpportunity({
    opportunity: { ...selectedOpportunity.value },
  });

  // Put the interaction to attach the opportunity to the interaction.
  await putInteraction({ id: id.value });
  nextTick(() => getInteraction({ id: id.value }));
  toggleOpportunityModal();
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

:deep(.selectable-item:hover) {
  cursor: pointer;
}

:deep(.c-card--footer) {
  margin: initial;

  &.bg-panel-action {
    background-color: initial !important;
    padding-inline: 0;
  }
}

:deep(.input-container) {
  padding-inline: var(--spacing-half);
}

.heading {
  font-size: var(--font-size-4);
  line-height: 1.5rem;
  font-weight: var(--font-weight-semibold);
  padding-block-start: var(--spacing-2);
  padding-inline: var(--spacing-3);
  position: relative;
  margin-right: var(--spacing-4);
  padding-top: initial;
}
.stack-wrap {
  overflow-y: auto;
  max-height: 400px;
  padding-block: var(--spacing-half);
  padding-inline-start: var(--spacing-half);
  padding-inline-end: var(--spacing-1);
}
.more-btn {
  font-size: var(--font-size-small);
  text-decoration: underline;
  font-weight: var(--font-weight-medium);
}

.opportunities {
  border-radius: 0;
  padding-inline: initial;

  box-shadow: initial;
  flex: 1;
  height: auto;
  max-height: 700px;
  overflow-y: hidden;

  &.search-active {
    background-color: var(--colour-panel-base);
  }

  :deep(.c-card--body) {
    flex-grow: 1;
  }

  :deep(input) {
    border-radius: 25px;
  }
}
.search-wrapper {
  margin-block-end: var(--spacing-2);
}
.opportunity-actions {
  padding-inline: var(--spacing-half);

  display: flex;
  gap: var(--spacing-2);
}

.add-opportunities {
  padding: var(--spacing-3);
  background-color: var(--colour-panel-base);
  margin: var(--spacing-4) var(--spacing-4) var(--spacing-2);

  p {
    font-style: italic;
  }
}

.add-opportunities__btn {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-semibold);
  display: inline-flex;
}

.add-btn-small {
  background-color: var(--colour-utility-action);
  position: absolute;
  right: var(--spacing-1);
  top: 0;
}

.opp-card {
  height: auto;
  min-height: var(--spacing-16);
}

.description {
  background-color: var(--colour-utility-white);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);
  border-radius: var(--border-radius-1);
  overflow: hidden;
  overflow-wrap: break-word;
  padding: var(--spacing-3);

  h3 {
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--spacing-1);
  }

  p {
    font-size: var(--font-size-4);
  }
}
.insight {
  min-width: 100%;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: var(--border-radius-1);
  margin-bottom: var(--spacing-4);
  background-color: var(--colour-data-jagged-ice);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--colour-data-jagged-ice);
  img {
    max-height: 150px;
    margin: var(--spacing-2);
    @media #{map-get($display-breakpoints, 'md-and-down')} {
      display: none;
    }
  }
  .insight-text {
    display: flex;
    flex-flow: column;
    align-items: center;

    p {
      font-size: var(--font-size-3);
      margin: var(--spacing-1);
      line-height: var(--spacing-4);
      max-width: 560px;
    }
  }
}

.search-actions {
  padding-top: var(--spacing-3)
}

.total-oppo {
  margin-left: auto;
}

.carousel {
  height: 170px;

  .carousel-slide {
    padding: 0 var(--spacing-5);
  }

  :deep(.carousel-prev) {
    left: 0;
    transform: translateY(-50%);
  }
  
  :deep(.carousel-next) {
    right: 0;
    transform: translateY(-50%);
  }
}
</style>
