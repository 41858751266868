<template>
  <div>
    <EnquiryNav
      v-if="!iframe"
      :active-area-prop="reportArea"
      :report-type="reportType"
      :alert-builder-dates="subtitles"
      hide-enquiry-buttons
    />
    <div class="container">
      <div class="description">
        <i>{{ inputData.description }}</i>
      </div>

      <router-view
        :report-type="reportType"
        @set-alert-dates="setAlertDates"
        @close-modal="closeModal"
        @alert-saved="handleAlertSaved"
      />

      <TwoColumnLayout
        class="columns-container"
        sidebar-width="400px"
      >
        <template #sidebar>
          <AlertMenu
            :queries="queries"
            :selected-query-id="selectedQueryId"
            @query-change="navigateToQueryById"
            @clause-change="goToReport"
          />
          <div>
            <p>
              <CustomButton
                v-if="isQuery"
                purpose="text"
                :label="t('delete')"
                @click="handleDeleteAlert"
              />
            </p>
          </div>
        </template>

        <template #content>
          <div
            id="results-table-wrapper"
            class="results-table-wrapper"
          >
            <FullPictureTable
              v-if="showReport && !isQuery"
              :drill-enabled="false"
              :search-mode="mode"
              :is-report-enabled="fullPictureEnabled"
              :hide-value-fields="mode === 'prospect'"
              :title="t('alert_builder')"
              :report-type="QUERY_BUILDER"
              :date-params="{
                date_from: 'today-3m',
                date_to: 'today',
                date_from2: 'today-3m-3m',
                date_to2: 'today-3m',
              }"
              @loaded="handleFPLoaded"
            />
            <SavedQueryTable
              v-if="showReport && isQuery"
              :id="selectedQueryId"
              :key="`${selectedQueryId}_${runCount}`"
              :report-type="QUERY_BUILDER"
              @loaded="enableMap"
              @update:date-ranges="handleDatesRanges"
              @is-variance="setIsAlertBuilderVariance"
            />
          </div>
        </template>
      </TwoColumnLayout>
    </div>
  </div>
</template>

<script setup>
import { CustomButton } from '@sales-i/dsv3';
import AlertMenu from '@/intelligence/views/Communities/AlertMenu.vue';
import { computed, ref, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useMq } from 'vue3-mq';
import { REPORT_AREA_ALERTS } from '@/intelligence/store/data/areas';
import { GET_ADDRESSES } from '@/crm/store/actionType';
import { PRODUCT_COMMUNITY_INSIGHTS, QUERY_BUILDER } from '@/intelligence/store/data/reportTypes';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { tCrm as t, dates } from '@sales-i/utils';
import { alertsScope } from '@/intelligence/router/urlBits';
import { useAlertBuilder } from '@/intelligence/composables/useAlertBuilder';
import useSystem from '@/shared/composables/useSystem';
import useShared from '@/intelligence/composables/useShared';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import useTemplateReport from '@/intelligence/composables/useTemplateReport';
import useFields from '@/shared/composables/useFields';
import useSavedQueries from '@/intelligence/composables/useSavedQueries';
import breakpoints from '@/shared/utils/breakpoints';
import TwoColumnLayout from '@/shared/components/Layout/TwoColumnLayout';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import FullPictureTable from '@/intelligence/components/FullPicture/FullPictureTable.vue';
import SavedQueryTable from '@/intelligence/views/Communities/SavedQueryTable.vue';
import { NEW_ALERT_IFRAME_ROUTE, NEW_ALERT_ROUTE } from '@/intelligence/router/routeNames';
import { navigateToUrl } from 'single-spa';

const mq = useMq();
const reportArea = REPORT_AREA_ALERTS;
const reportType = PRODUCT_COMMUNITY_INSIGHTS;
const store = useStore();
const vroute = useRoute();
const vrouter = useRouter();

const {
  alertBuilderBaseUrl,
  clearAllFilters,
  fetchBubbleData,
  goToReport,
  setCustomerBubble,
  runCount,
  setDates,
  setIsAlertBuilderVariance,
  clearProducts,
  resetAllDataClauses,
} = useAlertBuilder({ store, vroute });
const { iframe, showConfirmationModal } = useSystem({ store });
const { getInputData } = useShared({ store });
const { clearEnquiryFilters } = useEnquiries({ store });
const { fetchBubbleIds } = useTemplateReport({ store });
const { getFields } = useFields({ store });
const { queries, deleteAlert, getAllSavedAlerts, getSavedAlertById } = useSavedQueries({ store });

const showReport = ref(false);
const mode = ref('customer');
const fullPictureEnabled = ref(false);
const dateRangesObject = ref({});

const inputData = computed(() => {
  if (selectedQueryId.value !== null && isQuery.value) {
    let x = 0;
    let description = '';
    for (x; x < queries.value.length; x++) {
      if (queries.value[x].id === parseInt(selectedQueryId.value, 10)) {
        description = queries.value[x].query_name;
      }
    }
    let [title, promoLink] = description.split('|~~|');
    if (title.length > 0) {
      return {
        description: title,
        link: promoLink,
      };
    }
  }
  return getInputData(reportType);
});

// selectedQueryId should be a number ref
const selectedQueryId = ref(vroute.params.queryId);

const isQuery = computed(() => queries.value?.length > 0);
const isMobile = computed(() => breakpoints.smAndDown.includes(mq.current));

const subtitles = computed(() => {
  const format = (value) => dates.format(value, isMobile.value ? 'short' : 'report');
  const { date_from, date_to, date_from2, date_to2 } = dateRangesObject.value;
  return [
    `${format(date_from)} - ${format(date_to)}`,
    `${format(date_from2)} - ${format(date_to2)}`
  ];
});

const getAddresses = params => store.dispatch(`crm/customers/${GET_ADDRESSES}`, params);

onMounted(async () => {
  await getFields({  entity: 'interactions' });
  await fetchBubbleData();
  await getAllSavedAlerts();
  await fetchBubbleIds(['field','name', bubbles => { 
    setCustomerBubble(bubbles[0]); 
  }]);
  loadAlert();
});

onUnmounted(() => {
  clearAll();
});

const enableMap = async customers => {
  if (customers === undefined) {
    return;
  }
  await getAddresses(
    customers.map(c => ({
      id: parseInt(c.dimensions[0].entity_id, 10),
      account_number: c.dimensions[0].dimensions[1],
      title: c.dimensions[0].dimensions[0],
    }))
  );
};

const handleFPLoaded = async () => {
  await enableMap();
  fullPictureEnabled.value = true;
};

async function loadAlert() {
  if ([NEW_ALERT_ROUTE, NEW_ALERT_IFRAME_ROUTE].includes(vroute.name) &&
    selectedQueryId.value == null && queries.value.length > 0) {
    selectedQueryId.value = queries.value[0].id;
    vrouter.replace(`${alertBuilderBaseUrl.value}/${alertsScope}/${selectedQueryId.value}`);
  }
  // We have no saved alerts, so go to a fresh report
  if (queries.value.length === 0) {
    goToReport({ incrementRunCount: true });
    return;
  }
  if (selectedQueryId.value) {
    showReport.value = false;
    await clearAll();
    await getSavedAlertById(selectedQueryId.value);
    showReport.value = true;
  }
}

async function navigateToQueryById(id) {
  navigateToUrl(`${alertBuilderBaseUrl.value}/${alertsScope}/${id || ''}`);
}

async function handleAlertSaved() {
  await getAllSavedAlerts();
  loadAlert();
}

function closeModal(showConfirmation) {
  if (showConfirmation) {
    showConfirmationModal({
      heading: t('if_you_close_this_window_you_ll_lose_your_changes'),
      cancelLabel: t('no_keep_changes'),
      saveLabel: t('close'),
      closeOnUpdate: true,
      closeOnCancel: false,
      updateFunction: () => {
        navigateToQueryById(selectedQueryId.value);
        return true;
      },
    });
  } else {
    navigateToQueryById(selectedQueryId.value);
  }
}

async function clearAll() {
  clearAllFilters();
  clearEnquiryFilters();
  clearProducts();
  resetAllDataClauses();
}

function handleDeleteAlert() {
  showConfirmationModal({
    heading: t('delete_alert'),
    message: t('this_will_permanently_delete_this_alert_are_you_sure'),
    cancelLabel: t('cancel'),
    saveLabel: t('delete'),
    closeOnUpdate: true,
    closeOnCancel: false,
    updateFunction: async () => {
      await deleteAlert(selectedQueryId.value);
      await getAllSavedAlerts();
      navigateToUrl(`${alertBuilderBaseUrl.value}/${alertsScope}`);
      return true;
    },
  });
}

async function setAlertDates(dates) {
  await setDates(dates);
}

async function handleDatesRanges(dates) {
  const { dateRange, dateRange2 } = dates || {};
  if (dateRange?.from && dateRange?.to && dateRange2?.from && dateRange2?.to) {
    dateRangesObject.value = {
      date_from: dateRange.from,
      date_to: dateRange.to,
      date_from2: dateRange2.from,
      date_to2: dateRange2.to,
    };
  } else {
    console.error('Invalid dates object received:', dates);
    dateRangesObject.value = {
      date_from: dateRange.from,
      date_to: dateRange.to,
    };
  }
}

watch(() => vroute.params.queryId, async (newVal) => {
  selectedQueryId.value = newVal;
  await loadAlert();
});

// clear filters while navigating between tabs in iframe
onBeforeRouteLeave(() => {
  if (iframe.value) clearAll();
});
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.delete-modal {
  h3 {
    text-align: center;
    font-size: 1.5rem;
    margin: 0 0 var(--spacing-2) 0;
  }

  p,
  label {
    font-size: 1rem;
    margin: var(--spacing-2) 0;
  }

  .modal-content {
    p {
      margin: auto;
      text-align: center;
    }
  }

  div.svg-container {
    margin: auto;
  }
}

.container {
  padding: 0 0 var(--spacing-2);
  position: relative;
  background-image: linear-gradient(
    var(--colour-utility-white) 0 var(--spacing-3),
    var(--colour-panel-customer) var(--spacing-3) 100%
  );
  min-height: 250px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 0 var(--spacing-2) var(--spacing-2);
  }
}

.description {
  border-radius: var(--border-radius-1);
  display: grid;
  font-weight: var(--font-weight-semibold);
  place-content: center;
  padding: calc(var(--spacing-1) * 1.5);
  color: var(--colour-data-turmeric-label);
  background: var(--colour-data-turmeric-light);
  margin: 0 var(--spacing-2) var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin: 0 0 var(--spacing-2);
  }
}

.columns-container {
  :deep(.container.flow) {
    padding: 0;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding: var(--spacing-2);
    }
  }
}

:deep(.main) {
  padding-inline: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: var(--spacing-2);
  }
}

.filters-titles {
  h3 {
    font-weight: var(--font-weight-semibold);
  }

  p {
    margin-bottom: var(--spacing-2);
  }
}

h4 {
  margin: var(--spacing-2) 0;
}

.results-table-wrapper {
  position: relative;
}

.add-button {
  background-color: var(--colour-utility-white);
  top: 170px;
  position: sticky;
  display: flex;
  align-items: center;
  z-index: 10;

  :deep(button) {
    margin: var(--spacing-2) var(--spacing-2) var(--spacing-2) 0;
  }

  :deep(.icon-button) {
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-medium);

    span {
      margin-left: var(--spacing-2);
    }
  }
}

.alert-issue {
  color: white;
  background-color: var(--colour-brand-mandy);
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--border-radius-1);
}

.alert-management {
  display: flex;
  margin: var(--spacing-2) 0;

  :deep(button) {
    margin-right: var(--spacing-2);
  }
}

.bubbles {
  background: var(--colour-utility-white);
  z-index: 100;
  box-shadow: 0 0 var(--spacing-half) 1px var(--colour-panel-g-16);
  border-radius: var(--border-radius-1);
  padding: 0 var(--spacing-2);
  margin: 0 auto;
  overflow: auto;
  position: relative;
  max-width: calc(100% - 32px);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: absolute;
    top: 0;
    bottom: var(--spacing-2);
    right: 0;
    left: 30%;
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    left: 24%;
  }
}
</style>
