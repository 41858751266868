<!-- todo: translate -->
<template>
  <RagChip
    class="rag-chip__target"
    :value="progress"
    :green="67"
    :amber="33"
    suffix="%"
  />
  <div class="selected-target">
    <p class="selected-target__title">
      {{ item.requestParams.target_value.measurement.metric }}
    </p>
    <template
      v-for="field in item.requestParams.analysis_fields"
      :key="field.analysis_field"
    >
      <div class="bars">
        <TargetsWidgetChart
          :data="item"
        />
      </div>    

      <div class="doughnuts">
        <TargetsWidgetDoughnut
          :current-value="attainedValue"
          :total-value="targetValue"
          :remaining-value="remainingValue"
          :svg-size="164"
          :text-size="10"
          :text-size-subtext="5"
        >
          {{ cPrice(remainingValue) }}
          <template #subText>
            {{ t('to reach target') }}
          </template>
        </TargetsWidgetDoughnut>

        <TargetsWidgetDoughnut
          :current-value="elapsedDays"
          :total-value="totalDays"
          :remaining-value="remainingDays"
          :svg-size="164"
          :text-size="10"
          :text-size-subtext="5"
        >
          {{ remainingDays }}
          <template #subText>
            {{ t('days to go') }}
          </template>
        </TargetsWidgetDoughnut>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { t } from '@sales-i/utils';
import useCurrency from '@/shared/composables/useCurrency';
import useTargets from '@/intelligence/composables/useTargets';
import { RagChip } from '@sales-i/dsv3';
import TargetsWidgetChart from '@/admin/components/Dashboard/TargetsWidgetChart.vue';
import TargetsWidgetDoughnut from '@/admin/components/Dashboard/TargetsWidgetDoughnut.vue';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  settings: {
    type: Object,
    default: () => ({}),
  },
  selectedTargets: {
    type: Array,
    default: () => []
  },
  target: {
    type: Object,
    default: null
  },
  // formatTargetPeriod: {
  //   type: Function,
  //   default: () => true,
  // },
  measurementValue: {
    type: Function,
    default: () => true,
  },
  attained: {
    type: Function,
    default: () => true,
  }
});

const {
  daysFromInterval,
} = useTargets();
const { cPrice } = useCurrency();

const elapsedDays = ref(4); // Hard-coded for now. This will be dynamic in the future

// Time-based data for the doughnuts
const totalDays = computed(() => daysFromInterval[props.item.requestParams.target_period.interval] || 1);

// Rag chip data
const progress = computed(() => props.item.requestParams.attained.progress);

// Doughnut data
const targetValue = computed(() => props.item.requestParams.target_value.values[0]);
const attainedValue = computed(() => props.item.requestParams.attained.attained_value);
const remainingDays = computed(() => totalDays.value - elapsedDays.value);
const remainingValue = computed(() => targetValue.value - attainedValue.value);
</script>

<style lang="scss" scoped>
  // Overrides
  .rag-chip__target {
    position: absolute;
    top: var(--spacing-3);
    right: var(--spacing-1);
  }

  .selected-target {
    background-color: var(--colour-utility-white);
    box-shadow: 0 -2px 4px var(--colour-panel-g-24);
    padding: var(--spacing-2);
  }
  .doughnuts {
    display: flex;
    gap: var(--spacing-6);
    justify-content: center;
  }

  .selected-target__title {
    text-align: center; 
  }

  .selected-target__details {
    display: grid;
    gap: var(--spacing-3);
    grid-template-columns: 10rem auto 1fr;
    align-items: center;
  }

  .analysis-fields {
    display: flex;
    gap: var(--spacing-1);
  }
</style>