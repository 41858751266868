<template>
  <div>
    <EnquiryNav
      class="nav"
      :report-type="reportType"
      :dates="dateParams"
      :active-area-prop="defultReportArea"
      :is-date-filter-active="isDateFilterActive"
      hide-drill
      @toggle-date-filter="toggleDateFilter"
      @apply-filters="applyFilters"
    />
    <div class="container">
      <div class="description">
        <i>{{ inputData.description }}</i>
      </div>
      <DatePickerView
        v-if="isDateFilterActive"
        :report-type="reportType"
        :dates="dateParams"
        :active-area-prop="defultReportArea"
        :is-date-filter-active="isDateFilterActive"
        @toggle-date-filter="toggleDateFilter"
        @set-active-date="setDate"
      />
      <div
        v-else
        class="data-wrapper"
      >
        <div
          v-if="reportData.length"
          class="data-head"
        >
          <div :class="{ active: sortHeader === 'name' }">
            <CustomButton
              id="name"
              purpose="transparent"
              icon-name="arrow-down"
              icon-color="var(--colour-utility-black)"
              :icon-width="16"
              :icon-height="16"
              class="sort-btn"
              :class="sortOption"
              @click="sort('name')"
            >
              {{ t('sales_rep') }}
            </CustomButton>
          </div>
          <div
            v-for="(type, index) in interactionTypes"
            :key="index"
            :class="{ active: sortHeader === type.value }"
          >
            <CustomButton
              :id="index"
              purpose="transparent"
              icon-name="arrow-down"
              icon-color="var(--colour-utility-black)"
              :icon-width="16"
              :icon-height="16"
              class="sort-btn"
              :class="sortOption"
              @click="sort(type.value)"
            >
              {{ type.title }}
            </CustomButton>
          </div>
        </div>
        <div
          v-if="dataLoading && !lazyLoading"
          class="buffer"
        >
          <BufferImage
            color="var(--colour-utility-black)"
            float="center"
            class="loading-spinner"
          />
        </div>
        <TableWrapper
          v-else
          table-height="510px"
          :offset-y="offset"
          container="div"
          :enable-lazy-load="isLazyLoadAvailable"
          disable-shades
          :no-more-results-available="noMoreResultsAvailable"
          @set-offset="loadMoreData"
        >
          <div ref="data">
            <div
              v-for="(row, i) in reportData"
              :key="i"
              class="data-row"
            >
              <div class="name">
                <span :title="row.name">{{ row.name }}</span>
              </div>
              <div
                v-for="(type, index) in interactionTypes"
                :key="index"
              >
                {{ getFormattedNumber(row[type.value]) }}
                <span
                  :class="{ disabled: row[type.value] === 0 }"
                  class="link"
                  role="button"
                  tabindex="0"
                  @click="goToDrilledReport(row, type)"
                  @keydown.enter="goToDrilledReport(row, type)"
                >
                  {{ t('view') }}
                </span>
              </div>
            </div>
          </div>
          <NoResults
            v-if="reportData && !reportData.length && isReportEnabled && !dataLoading"
            class="no-results"
          />
        </TableWrapper>
        <ReportFooter
          v-if="reportData.length"
          :date-params="dates"
          :request-params="exportRequestParams"
          :report-type="reportType"
          :title="t('export')"
        />
      </div>
      <Teleport to="#modal-teleport-target">
        <!-- Interaction Outcomes Details modal -->
        <CustomModal
          id="detail-modal"
          class="detail-modal"
          :show-modal="isDetailsModalVisible"
          position="center"
          @close-modal="closeModal"
        >
          <InteractionOutcomesDetail
            v-if="isDetailsModalVisible"
            :details-info="detailsInfo"
            :report-index="reportIndex"
            @handle-report-index="handleReportIndex"
          />
        </CustomModal>
      </Teleport>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeUnmount, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  CLEAR_REPORT_DATA,
  FETCH_DATES,
  APPLY_DATE_RANGE,
  FETCH_REPORT_DATA,
  APPLY_SORT,
  APPLY_NEW_FILTER,
  CLEAR_FILTERS,
  APPLY_OFFSET,
  RESET_REPORT_PARAMETERS,
  SAVE_REPORT_PARAMETERS,
  CLEAR_ENQUIRY_FILTERS,
  APPLY_LIMIT,
  REFRESH_REPORT
} from '@/intelligence/store/actionType';
import { GET_BY_ENTITY } from '@/shared/store/actionType';
import { DATE_FILTER_KEYS } from '@/intelligence/store/data/dateFilters';
import DatePickerView from '@/intelligence/components/Date/DatePickerView';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import { REPORT_AREA_PERFORMANCE } from '@/intelligence/store/data/areas';
import { INTERACTION_OUTCOMES } from '@/intelligence/store/data/reportTypes';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import { BufferImage, CustomButton, CustomModal } from '@sales-i/dsv3';
import InteractionOutcomesDetail from '@/intelligence/views/Insights/InteractionOutcomes/InteractionOutcomesDetail';
import NoResults from '@/intelligence/components/Shared/NoResults';
import isRolldate from '@/intelligence/store/utils/isRolldate';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import { tCrm as t, getLocale } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

const store = useStore();
const vroute = useRoute();
const vrouter = useRouter();

const isDateFilterActive = ref(true);
const defultReportArea = ref(REPORT_AREA_PERFORMANCE);
const sortDescending = ref(false);
const sortHeader = ref('name');
const isDetailsModalVisible = ref(false);
const isReportEnabled = ref(false);
const reportIndex = ref(1);
const topLevelReportIndex = ref(1);
const dates = ref({});
const detailsInfo = ref({});
const pageSize = ref(100);
const reportType = ref(INTERACTION_OUTCOMES);
const offset = ref(0);
const noMoreResultsAvailable = ref(false);
const isLazyLoadAvailable = ref(true);
const lazyLoading = ref(false);
const interactionTypes = ref([
  {
    title: t('total_interactions_made'),
    shortTitle: t('interactions_made'),
    value: 'interactions_made',
  },
  {
    title: t('total_interactions_allocated'),
    shortTitle: t('interactions_allocated'),
    value: 'interactions_allocated',
  },
]);

const dataLoading = computed(() => store.state.intelligence.shared.loading);
const fetchedDates = computed(() => store.state.intelligence.calendar.fetchedDates);
const fetchedData = computed(() => store.state.intelligence.shared.fetchedData);
const reportData = computed(() => store.state.intelligence.reportLazyLoad.rows);
const exportRequestParams = computed(() => ({
  from: dates.value.date_from,
  to: dates.value.date_to,
}));

const getInputData = rt => store.getters['intelligence/shared/getInputData'](rt);
const getReportData = () => store.getters['intelligence/shared/getReportData']();

const inputData = computed(() => getInputData(reportType.value));
const dateParams = computed(() => {
  const data = {};

  Object.keys(vroute.query).forEach((key) => {
    if (DATE_FILTER_KEYS.includes(key)) {
      data[key] = vroute.query[key];
    }
  });

  return data;
});
const sortOption = computed(() => sortDescending.value ? 'desc' : 'asc');
const getBaseLevelReportIndex = computed(() => {
  const fetchedDataKeys = Object.keys(fetchedData.value);
  const fetchedDataValues = [];
  for (const key in fetchedData.value) {
    fetchedDataValues.push(fetchedData.value[key].data);
  }
  if (fetchedDataValues.length === 1) return parseInt(fetchedDataKeys[0]);

  let baseLevelReportCount = 1;
  fetchedDataValues.forEach((value, index) => {
    if (value[0] && value[0].customer) return;
    baseLevelReportCount = index;
  });

  return parseInt(fetchedDataKeys[baseLevelReportCount]);
});

watch(() => ({
  date_from: vroute.query.date_from,
  date_to: vroute.query.date_to
}), to => {
  if (!to.date_from || !to.date_to) isDateFilterActive.value = true;
});

onBeforeUnmount(() => {
  clearReportData();
  clearFilters();
  resetReportParameters();
});

onMounted(async() => {
  clearEnquiryFilters();
  sortColumn();

  if (vroute.query.type) isLazyLoadAvailable.value = false;

  const { date_from, date_to, filter } = vroute.query;

  if (filter) {
    if (Array.isArray(filter)) {
      filter.forEach(async (val, index) => {
        applyFilterFromUrl(val, index);
      });
    } else {
      applyFilterFromUrl(filter, 0);
    }
  }

  if (date_from && date_to) {
    hideDateFilter();
    await setDate({
      date_from,
      date_to,
    });
  }

  if (Object.keys(vroute.query).includes('type')) {
    const { type, rep_id } = vroute.query;
    const interaction = interactionTypes.value.find(
        (interaction) => interaction.value === type
      ),
      representative = reportData.value.find((rep) => rep.rep_id === rep_id);

    handleDrill(representative, interaction);
  }
});

const fetchDates = params => store.dispatch(`intelligence/calendar/${FETCH_DATES}`, params);
const applyDate = params => store.dispatch(`intelligence/shared/${APPLY_DATE_RANGE}`, params);
const fetchReportData = params => store.dispatch(`intelligence/shared/${FETCH_REPORT_DATA}`, params);
const applySort = params => store.dispatch(`intelligence/performanceInsight/${APPLY_SORT}`, params);
const applyNewFilter = params => store.dispatch(`intelligence/performanceInsight/${APPLY_NEW_FILTER}`, params);
const clearReportData = params => store.dispatch(`intelligence/shared/${CLEAR_REPORT_DATA}`, params);
const clearFilters = params => store.dispatch(`intelligence/performanceInsight/${CLEAR_FILTERS}`, params);
const applyOffset = params => store.dispatch(`intelligence/shared/${APPLY_OFFSET}`, params);
const applyLimit = params => store.dispatch(`intelligence/shared/${APPLY_LIMIT}`, params);
const resetReportParameters = params => store.dispatch(`intelligence/reportLazyLoad/${RESET_REPORT_PARAMETERS}`, params);
const saveReportParameters = params => store.dispatch(`intelligence/reportLazyLoad/${SAVE_REPORT_PARAMETERS}`, params);
const clearEnquiryFilters = params => store.dispatch(`intelligence/enquiry/${CLEAR_ENQUIRY_FILTERS}`, params);
const refreshReport = params => store.dispatch(`intelligence/shared/${REFRESH_REPORT}`, params);
const getCustomSchemaFields = params => store.dispatch(`admin/fields/${GET_BY_ENTITY}`, params);

async function applyFilterFromUrl(val) {
  const filterVal = val.split(',');
  const id = filterVal[0];
  await getCustomSchemaFields({ entity: 'interactions' });
  applyNewFilter({
    type: id,
    value: filterVal.slice(1).map(n => id === 'user' ? n : Number(n)),
  });
  refreshReport();
  await fetchReportData({ reportType: reportType.value });
}
async function setDate(event) {
  let newDates = event.dates || event;
  const eventValues = Object.values(newDates);
  const data = {};

  if (eventValues.includes(null)) return;

  if (eventValues.includes(undefined)) newDates = dateParams.value;

  Object.keys(vroute.query).forEach((key) => {
    if (!DATE_FILTER_KEYS.includes(key)) {
      data[key] = vroute.query[key];
    }
  });

  const newQuery = {
    ...newDates,
  };

  if (vroute.query.type) {
    newQuery.type = vroute.query.type;
    newQuery.rep_id = vroute.query.rep_id;
  }

  const route = vrouter.resolve({
    query: {
      ...vroute.query,
      ...newQuery
    },
  });
  navigateToUrl(route.href);

  if (isRolldate(newDates)) {
    await fetchDates(newDates);
    dates.value = fetchedDates.value;
  } else {
    dates.value = newDates;
  }

  applyDate(dates.value);
  hideDateFilter();
  resetReportParameters();
  offset.value = 0;
  applyLimit(100);
  applyOffset(offset.value);
  await generateReportData();
  handleReportIndex();
  setReportsIndex();
  checkForMoreResults();
}
async function generateReportData() {
  isReportEnabled.value = false;
  topLevelReportIndex.value = await fetchReportData({
    reportType: reportType.value,
  });
  saveReportParameters({ rows: getReportData() });
  isReportEnabled.value = true;
}
function setReportsIndex() {
  topLevelReportIndex.value = reportIndex.value = getBaseLevelReportIndex.value;
}
function sortColumn() {
  applySort({
    header: sortHeader.value,
    option: sortOption.value,
  });
}
function toggleDateFilter() {
  isDateFilterActive.value = !isDateFilterActive.value;
}
function hideDateFilter() {
  isDateFilterActive.value = false;
}
function goToDrilledReport(representative, interaction) {
  if (representative[interaction.value] === 0) return;

  const { date_from, date_to } = vroute.query;
  offset.value = 0;
  applyOffset(offset.value);
  handleDrill(representative, interaction);

  const route = vrouter.resolve({
    query: {
      date_from,
      date_to,
      type: interaction.value,
      rep_id: representative.rep_id,
    },
  });
  history.replaceState({}, '', route.href);
  isLazyLoadAvailable.value = false;
}
async function sort(header) {
  if (header === sortHeader.value) {
    sortDescending.value = !sortDescending.value;
  } else {
    sortHeader.value = header;
    sortDescending.value = false;
  }

  offset.value = 0;
  applyOffset(offset.value);
  resetReportParameters();
  handleReportIndex();
  topLevelReportIndex.value = reportIndex.value;
  sortColumn();
  await generateReportData();
  checkForMoreResults();
}
function closeModal() {
  const { date_from, date_to } = vroute.query;

  isDetailsModalVisible.value = false;
  const route = vrouter.resolve({
    query: {
      date_from,
      date_to,
    },
  });
  history.replaceState({}, '', route.href);
  applySort({
    header: sortHeader.value,
    option: sortOption.value,
  });
  applyOffset(offset.value);
  isLazyLoadAvailable.value = true;
}
function handleReportIndex() {
  reportIndex.value++;
}
function handleDrill(representative, interaction) {
  handleReportIndex();
  isDetailsModalVisible.value = true;
  detailsInfo.value = {
    ...representative,
    ...interaction,
    ...dates.value,
    reportIndex: reportIndex.value,
  };
}
function getFormattedNumber(sum) {
  return Number(sum).toLocaleString(getLocale(), { maximumFractionDigits: 2, });
}
function checkForMoreResults() {
  noMoreResultsAvailable.value = reportData.value.length < offset.value + pageSize.value;
}
async function loadMoreData() {
  lazyLoading.value = true;
  offset.value += pageSize.value;
  applyOffset(offset.value);
  await fetchReportData({ reportType: reportType.value });
  saveReportParameters({
    rows: [...getReportData()],
  });
  handleReportIndex();
  checkForMoreResults();
  lazyLoading.value = false;
}
function applyFilters() {
  offset.value = 0;
  applyOffset(offset.value);
  handleReportIndex();
  setReportsIndex();
  resetReportParameters();
  saveReportParameters({
    rows: [...getReportData()],
  });
  checkForMoreResults();
}
</script>
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.container {
  padding: 0 var(--spacing-2) var(--spacing-5);
  background-image: linear-gradient(
    var(--colour-utility-white) 0 var(--spacing-3),
    var(--colour-panel-performance) var(--spacing-3) 100%
  );

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 0 var(--spacing-2) var(--spacing-2);
  }
}

.description {
  border-radius: var(--border-radius-1);
  display: grid;
  font-weight: var(--font-weight-semibold);
  place-content: center;
  padding: calc(var(--spacing-1) * 1.5);
  color: var(--colour-data-puerto-rico-label);
  background: var(--colour-data-puerto-rico-light);
  margin: 0 0 var(--spacing-2);
}

.data-wrapper {
  font-size: var(--font-size-small);
  padding-bottom: 50px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: var(--font-size-5);
    padding-bottom: 0;
  }
}

.data-head {
  background: var(--colour-panel-action);
  display: flex;
  justify-content: center;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    gap: var(--spacing-2);
  }

  > div {
    width: 33%;
    display: flex;
    align-items: center;
    position: relative;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: auto;
      flex-basis: 220px;

      &:last-of-type,
      &:first-of-type {
        flex-basis: 260px;
      }
    }

    &.active {
      .button {
        text-decoration: none;
        color: var(--colour-utility-black);
      }

      .sort-btn {
        &.asc {
          &:deep(.icon) {
            transform: rotate(180deg);
          }
        }

        &:deep(.icon) {
          display: block;
        }
      }
    }

    .button,
    span {
      color: var(--colour-utility-action);
      font-weight: var(--font-weight-semibold);
      text-decoration: underline;
      text-align: left;
      padding: var(--spacing-1);

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        padding: var(--spacing-4) var(--spacing-2);
      }
    }

    .sort-btn {
      &:deep(.icon) {
        display: none;
        position: absolute;
        right: var(--spacing-half);
        top: var(--spacing-half);
        cursor: pointer;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
          right: var(--spacing-1);
          top: var(--spacing-1);
        }
      }
    }

    &:deep(.button-container) {
      margin-left: 0;
    }
  }
}

.data-row {
  display: flex;
  justify-content: center;
  background: var(--colour-utility-white);

  &:nth-of-type(2n) {
    background: var(--colour-panel-g-2);
  }

  &:last-of-type {
    padding-bottom: var(--spacing-1);
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    gap: var(--spacing-2);
  }

  div {
    padding: var(--spacing-1);
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: right;

    &.name {
      justify-content: left;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: auto;
      padding: var(--spacing-1) var(--spacing-2);
      flex-basis: 220px;

      &:last-of-type,
      &:first-of-type {
        flex-basis: 260px;
      }
    }

    .link {
      color: var(--colour-utility-action);
      font-weight: var(--font-weight-semibold);
      text-decoration: underline;
      padding-left: var(--spacing-2);
      cursor: pointer;

      &.disabled {
        color: var(--colour-panel-g-32);
        text-decoration: line-through;
      }
    }
  }
}

.buffer {
  background: var(--colour-panel-g-2);
  padding: var(--spacing-3) 0;
}

.detail-modal {
  &:deep(.close-button) {
    position: absolute;
    top: var(--spacing-1);
    right: var(--spacing-1);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      top: var(--spacing-3);
      right: var(--spacing-3);
    }
  }

  &:deep(.header) {
    padding: 0;
  }

  &:deep(.modal) {
    width: 98%;
    max-width: 98%;
  }

  &:deep(.content-wrapper) {
    padding-bottom: 0;
  }

  &:deep(.content) {
    max-height: 96vh;
    overflow: hidden;
  }

  &:deep(.footer-section) {
    right: 0;
  }
}

.nav {
  &:deep(.drill-btn) {
    display: none;
  }
}

.no-results {
  padding: var(--spacing-3) 0;
}

.data-rows {
  max-height: 470px;
  overflow: auto;

  > div {
    position: relative;
    overflow: hidden;
  }
}
</style>
