<template>
  <CustomCard
    class="dashboard-widget"
    purpose="reversed"
    footer-color="grey"
    :class="[classes, { 'expired-widget__card': isWidgetNotInTargets }]"
    small
  >
    <template #header>
      <h3>{{ computedItem?.name }}</h3>
    </template>
    <LazyLoadContentWrapper v-slot="{ contentVisible }">
      <template v-if="contentVisible">
        <TemplateReportContent
          v-if="isTemplateReport"
          :item="computedItem"
          :settings="settings"
          @update:menu-options="handleUpdateMenuOptions"
        />
        <SalesOpsReportContent
          v-else-if="computedItem?.reportType == SALES_OPPORTUNITY"
          :item="computedItem"
          :settings="settings"
          @update:menu-options="handleUpdateMenuOptions"
        />
        <InteractionOutcomesContent
          v-else-if="computedItem?.reportType == INTERACTION_OUTCOMES"
          :item="computedItem"
          :settings="settings"
          @update:menu-options="handleUpdateMenuOptions"
        />
        <InteractionSpendSummaryContent
          v-else-if="computedItem?.reportType == INTERACTION_SPEND_SUMMARY"
          :item="computedItem"
          :settings="settings"
          @update:menu-options="handleUpdateMenuOptions"
        />
        <CustomerActivityContent
          v-else-if="computedItem?.reportType == CUSTOMER_ACTIVITY"
          :item="computedItem"
          :settings="settings"
          @update:menu-options="handleUpdateMenuOptions"
        />
        <TrendsReportContent
          v-else-if="computedItem?.reportType == TRENDS"
          :item="computedItem"
          :settings="settings"
          @update:menu-options="handleUpdateMenuOptions"
        />
        <SalesOverviewContent
          v-else-if="computedItem?.reportType === SALES_OVERVIEW"
          :item="computedItem"
          :settings="settings"
        />
        <TargetContent
          v-else-if="computedItem?.reportType === TARGET"
          :item="computedItem"
          :settings="settings"
        />
        <p
          v-else
          class="p-2"
        >
          {{ computedItem?.description }}
        </p>
      </template>
      <div
        v-else
        class="my-10 flex justify-center"
      >
        <BufferImage />
      </div>
    </LazyLoadContentWrapper>
    <template #footer>
      <ButtonGroup>
        <!-- TODO: when fixed policy in place remove false below -->
        <CustomDropdown
          v-if="isFixed && !isTarget"
          purpose="reversed"
          class="widget-actions"
          menu-position="right"
          :close-on-selection="true"
          :items="contextMenuOptions"
          :icon-color="computedItem?.fixed ? '' : 'var(--colour-utility-action)'"
          @click="handleContextMenuClick"
        />
        <div class="middle">
          <CustomButton
            v-if="computedItem?.settings?.url"
            small
            purpose="action"
            @click="emit('view', computedItem)"
          >
            {{ t('view_details') }}
          </CustomButton>
        </div>

        <IconButton
          purpose="reversed"
          :icon-name="isExpanded ? 'shrink' : 'expand'"
          icon-color="var(--colour-utility-action)"
          icon-only
          width="24"
          height="24"
          @click="handleExpand"
        />
        <IconBase
          v-if="!computedItem?.fixed"
          icon-name="move"
          icon-color="var(--colour-utility-action)"
          class="handle"
          icon-only
          width="24"
          height="24"
        />
      </ButtonGroup>
    </template>
  </CustomCard>

  <div
    v-if="isTarget && isWidgetNotInTargets"
    class="expired-widget flow"
  >
    <div class="expired-widget__content">
      <p>{{ t('This target is deleted, expired or is no longer shared with you') }}</p>
      <CustomButton
        small
        purpose="action"
        @click="handleRemoveWidget"
      >
        {{ t('Dismiss') }}
      </CustomButton>
    </div>
  </div>
</template>

<script setup>
// Widget
import { computed, ref, watch } from 'vue';
import { BufferImage, CustomButton, CustomCard, CustomDropdown, IconBase, IconButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { tCrm as t, dates } from '@sales-i/utils';
import { TEMPLATE_REPORT_TYPES, SALES_OPPORTUNITY, INTERACTION_OUTCOMES, INTERACTION_SPEND_SUMMARY, CUSTOMER_ACTIVITY, TRENDS, SALES_OVERVIEW, TARGET } from '@/intelligence/store/data/reportTypes';
import { DEFAULT_WIDGET_SETTINGS } from '@/intelligence/components/Dashboard/constants';
import TemplateReportContent from '@/intelligence/components/Dashboard/Content/TemplateReportContent.vue';
import SalesOpsReportContent from '@/intelligence/components/Dashboard/Content/SalesOpsReportContent.vue';
import InteractionOutcomesContent from '@/intelligence/components/Dashboard/Content/InteractionOutcomesContent.vue';
import LazyLoadContentWrapper from '@/shared/components/LazyLoadContentWrapper.vue';
import InteractionSpendSummaryContent from '@/intelligence/components/Dashboard/Content/InteractionSpendSummaryContent.vue';
import CustomerActivityContent from '@/intelligence/components/Dashboard/Content/CustomerActivityContent.vue';
import TrendsReportContent from '@/intelligence/components/Dashboard/Content/TrendsReportContent.vue';
import SalesOverviewContent from '@/intelligence/components/Dashboard/Content/SalesOverviewContent.vue';
import TargetContent from '@/intelligence/components/Dashboard/Content/TargetContent.vue';
import modifyUrl from '@/shared/utils/modifyUrl';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  isExpanded: {
    type: Boolean,
    default: false,
  },
  widgetsNotInTargets: {
    type: Array,
    default: () => [],
  },
});

// this is only used for Viewing the Widget atm
// as it computes the correct value of the Roll Dates
const computedItem = computed(() => {
  const url = props.item?.settings?.url;
  const urlParams = new URLSearchParams(url);
  const shouldRollDates = urlParams.get('rolldates') === 'true';
  if (shouldRollDates) {
    let updatedDate = dates.getDateTime(props.item?.created_date || new Date());
    updatedDate = updatedDate.set({ hour: 0, minute: 0, second: 0 });
    const requestParams = props.item?.requestParams;
    let modifiedDateFrom = dates.getDateTime(requestParams?.date_from);
    let modifiedDateTo = dates.getDateTime(requestParams?.date_to);
    if (modifiedDateFrom && modifiedDateTo) {
      const diff = dates.getDiff(new Date(), updatedDate);
      modifiedDateFrom = modifiedDateFrom.plus({ years: diff.years, months: diff.months, days: diff.days, hours: diff.hours });
      modifiedDateTo = modifiedDateTo.plus({ years: diff.years, months: diff.months, days: diff.days, hours: diff.hours });
      const dateFromString = `${modifiedDateFrom.year}-${modifiedDateFrom.month}-${modifiedDateFrom.day}`;
      const dateToString = `${modifiedDateTo.year}-${modifiedDateTo.month}-${modifiedDateTo.day}`;
      let modifiedDateFrom2 = dates.getDateTime(requestParams?.date_from2);
      let modifiedDateTo2 = dates.getDateTime(requestParams?.date_to2);
      let dateFrom2String;
      let dateTo2String;
      if (modifiedDateFrom2 && modifiedDateTo2) {
        modifiedDateFrom2 = modifiedDateFrom2.plus({ years: diff.years, months: diff.months, days: diff.days, hours: diff.hours });
        modifiedDateTo2 = modifiedDateTo2.plus({ years: diff.years, months: diff.months, days: diff.days, hours: diff.hours });
        dateFrom2String = `${modifiedDateFrom2.year}-${modifiedDateFrom2.month}-${modifiedDateFrom2.day}`;
        dateTo2String = `${modifiedDateTo2.year}-${modifiedDateTo2.month}-${modifiedDateTo2.day}`;
      }
      const newUrl = modifyUrl(props.item.settings.url, location.href, {
        date_from: dateFromString,
        date_to: dateToString,
        ...(dateFrom2String && {date_from2: dateFrom2String}),
        ...(dateTo2String && {date_to2: dateTo2String})
      });
      return {
        ...props.item,
        requestParams: {
          ...props.item.requestParams,
          date_from: dateFromString,
          date_to: dateToString,
          ...(dateFrom2String && {date_from2: dateFrom2String}),
          ...(dateTo2String && {date_to2: dateTo2String})
        },
        settings: {
          ...props.item.settings,
          url: `${newUrl.pathname}${newUrl.search}`
        }
      };
    }
  }
  return props.item;
});

watch(() => computedItem.value.settings, (to) => {
  settings.value = {
    ...DEFAULT_WIDGET_SETTINGS,
    ...to
  };
}, { deep: true });

const emit = defineEmits(['view', 'dragStarted', 'edit', 'delete', 'expand', 'patch']);

const widgetSpecificMenuOptions = ref([]);

const isTemplateReport = computed(() => TEMPLATE_REPORT_TYPES.includes(props.item.reportType));
const isWidgetNotInTargets = computed(() => {
  return props.widgetsNotInTargets.includes(props.item.id);
});

const classes = computed(() => ({
  fixed: props.item.fixed === true,
  expanded: props.isExpanded,
}));

const isTarget = computed(() => props.item.reportType === TARGET);
const isFixed = computed(() => computedItem.value?.fixed === false);

const settings = ref({
  ...DEFAULT_WIDGET_SETTINGS,
  ...computedItem.value.settings,
});

function handleExpand() {
  emit('expand', !props.isExpanded);
}

/** CONTEXT MENU  */
const contextMenuOptions = computed(() => {
  let links = [];
  const { id, dashboard_id } = props.item;
  // general options
  links.push(
    {
      action: 'edit',
      title: t('edit'),
      value: props.item,
    },
    {
      action: 'showSettings',
      title: t('settings'),
      value: { widget: props.item, settings: settings.value },
    }
  );
  // widget specific options
  links.push(...widgetSpecificMenuOptions.value);
  // general options
  links.push({
    action: 'patch',
    title: t('reset'),
    value: {
      id,
      dashboard_id,
      settings: {
        ...DEFAULT_WIDGET_SETTINGS,
        url: props.item.settings?.url,
      },
    },
  });
  links.push({
    action: 'delete',
    title: t('delete'),
    value: props.item,
  });
  return links;
});

// Add handler for removing widget
function handleRemoveWidget() {
  emit('delete', props.item);
}

function handleContextMenuClick({ action, value }) {
  if (action == 'patch' && value.settings) settings.value = {
    ...DEFAULT_WIDGET_SETTINGS,
    ...value.settings
  };
  emit(action, value);
}

function handleUpdateMenuOptions(value) {
  widgetSpecificMenuOptions.value = value;
}

</script>

<style lang="scss" scoped>
.dashboard-widget {
  min-height: 200px;
  min-width: 300px;
  position: relative;
  height: inherit;
  padding: var(--spacing-1);
  padding-top: var(--spacing-3);

  /** let's save some space in widgets */
  :deep(.c-card--body) {
    overflow-y: auto;
  }

  :deep(.c-card--footer) {
    margin: 0 calc(var(--spacing-1) * -1) calc(var(--spacing-1) * -1);
    padding: var(--spacing-2) var(--spacing-1);
  }

  &.expanded {
    width: 100%;
    max-width: 100%;
  }
}

.button-group {
  flex-wrap: nowrap;

  .middle {
    flex: 100;
    display: block;

    button {
      margin: 0 auto;
      width: auto;
    }
  }
}

h3 {
  text-align: center;
  font-weight: var(--font-weight-semibold);
  padding-inline: var(--spacing-6);
}

.widget-actions {
  max-width: 32px;
  :focus, :hover {
    :deep(svg) {
      fill: var(--colour-utility-white);
    }
  }
}

:deep(.c-card--footer) {
  margin-top: 0;
}

.handle {
  cursor: move;
}

.expired-widget {
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px dashed var(--colour-panel-g-16);
  border-radius: var(--border-radius-1);
  position: absolute;
  display: grid;
  place-content: center;
  text-align: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--spacing-8);

  & p {
    background: white;
    font-size: var(--font-size-4);
  }
}

.expired-widget__content {
  display: grid;
  gap: var(--spacing-2);
  place-items: center;
  max-width: 276px;
}

.expired-widget__card {
  box-shadow: none;
}
</style>
