<template>
  <div>
    <EnquiryNav
      :active-area-prop="REPORT_AREA_PERFORMANCE"
      :report-type="SALES_OPPORTUNITY"
      hide-enquiry-buttons
    />
    <TwoColumnLayout
      :key="reportRefreshCounter"
      :title="REPORT_AREA_PERFORMANCE"
      :description="inputData.description"
      @sidebar-menu-button-click="showMenu"
    >
      <template #sidebar>
        <div class="sidebar-menu date-filter">
          <DateRange
            :model-value="vroute.query"
            @update:model-value="dateRangeChangeHandler"
          />
          <ExportModal
            :title="t('export_all_stages')"
            :show-modal="isExportModalVisible"
            :report-type="SALES_OPPORTUNITY"
            :report-area="REPORT_AREA_PERFORMANCE"
            :request-params="requestParams"
            :date-params="fetchedDates"
            @click="toggleExportModal"
          />
        </div>
      </template>
      <template #content>
        <SalesOpportunity
          :status="status"
          @update:status="handleOpportunityTypeUpdate"
        />
      </template>
    </TwoColumnLayout>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { tCrm as t } from '@sales-i/utils';
import { useRoute, useRouter } from 'vue-router';
import TwoColumnLayout from '@/shared/components/Layout/TwoColumnLayout';
import SalesOpportunity from '@/intelligence/components/SalesOpportunity/SalesOpportunity';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import ExportModal from '@/intelligence/components/ReportFooter/ExportModal.vue';
import { REPORT_AREA_PERFORMANCE } from '@/intelligence/store/data/areas';
import { CUSTOM_ABS_DATES_OPTION_VALUE, whichDateOption } from '@/intelligence/store/data/dateFilters';
import { SALES_OPPORTUNITY } from '@/intelligence/store/data/reportTypes';
import DateRange from '@/intelligence/components/Date/DateRange.vue';
import useCalendar from '@/intelligence/composables/useCalendar';
import useShared from '@/intelligence/composables/useShared';
import usePerformanceInsight from '@/intelligence/composables/usePerformanceInsight';

const store = useStore();
const vroute = useRoute();
const vrouter = useRouter();

const emit = defineEmits(['openModal']);

const { fetchDates, fetchedDates } = useCalendar({ store });
const { reportRefreshCounter, getInputData } = useShared({ store });
const { setFilterTitle, fetchStagesData } = usePerformanceInsight({ store });

const isExportModalVisible = ref(false);

const inputData = computed(() => getInputData(SALES_OPPORTUNITY));

const requestParams = computed(() => ({
  entity: 'cust',
  order: 'high',
  status: vroute.query.status,
}));

onMounted(() => {
  dateRangeChangeHandler(vroute.query);
});

const status = computed(() => vroute.query.status || 'open');

function showMenu() {
  emit('openModal');
}

async function dateRangeChangeHandler({ value, date_from, date_to }) {
  if (value === CUSTOM_ABS_DATES_OPTION_VALUE && (!date_from || !date_to)) {
    await fetchDates({ date_from: date_from || 'today/y', date_to: date_to || 'today' });
    date_from = fetchedDates.value.date_from;
    date_to = fetchedDates.value.date_to;
  } else {
    date_from = date_from || 'today/y';
    date_to = date_to || 'today';
    value = value || whichDateOption({ value, date_from, date_to }).value;
  }
  await vrouter.replace({ query: { ...vroute.query, status: status.value, value, date_from, date_to } });
  await fetchDates({ date_from, date_to });
  loadData();
}

async function handleOpportunityTypeUpdate(value) {
  await vrouter.replace({ query: { ...vroute.query, status: value } });
  loadData();
}

function loadData() {
  fetchStagesData({ 
    date_from: fetchedDates.value.date_from, 
    date_to: fetchedDates.value.date_to, 
    status: status.value 
  });
  setFilterTitle(whichDateOption(vroute.query).text);
}

function toggleExportModal() {
  isExportModalVisible.value = !isExportModalVisible.value;
}
</script>

<style lang="scss" scoped>
@import "@/shared/assets/scss/_variables";

.sales-opportunity-report {
  padding-right: var(--spacing-4);
  padding-left: var(--spacing-4);
  width: 100%;
  margin: auto;
  position: relative;
  background: var(--colour-panel-g-0);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: var(--spacing-2);
  }
}
.sidebar.hide {
  .date-filter {
    display: none;
    opacity: 0;
  }
}

:deep(.area.performance .container) {
  padding-bottom: 100px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-bottom: var(--spacing-2);
  }
}

:deep(.action.export-button) {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
    background-color: transparent;
    color: var(--colour-utility-action);
    width: auto;
  }

  &:hover {
    background-color: transparent;
  }
}
</style>