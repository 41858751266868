import { DELETE_BY_ID, GET_ALL, GET_BY_ID, GET_CUSTOMER_ALERTS_BY_ID, PATCH_BY_ENTITY, POST_BY_ENTITY, SET_UNIQUE_CUSTOMERS } from '@/shared/store/actionType';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default function useSavedQueries({ store = useStore() } = {}) {
  const savedQueryData = computed(() => store.state.intelligence.savedQuery.data);
  const uniqueCustomers = computed(() => store.state.intelligence.savedQuery.uniqueCustomers);
  const customerAlerts = computed(() => store.state.intelligence.savedQuery.customerAlerts);
  const savedAlertErrors = computed(() => store.state.intelligence.savedQuery.errors);
  const queries = computed(() => store.state.intelligence.savedQuery.queries || []);

  const selectedQueryId = computed(() => savedQueryData.value.id);

  const shouldShowAlerts = computed(() => (
    customerAlerts.value.saved_queries?.length > 0 
    || customerAlerts.value.child_saved_queries?.length > 0));

  const getCustomerAlerts = id => store.dispatch(`intelligence/savedQuery/${GET_CUSTOMER_ALERTS_BY_ID}`,id);
  const getSavedAlertById = (id) => store.dispatch(`intelligence/savedQuery/${GET_BY_ID}`, id);
  const getAllSavedAlerts = params => store.dispatch(`intelligence/savedQuery/${GET_ALL}`, params);
  const setUniqueCustomers = params => store.dispatch(`intelligence/savedQuery/${SET_UNIQUE_CUSTOMERS}`, params);
  const patchByEntity = (params) => store.dispatch(`intelligence/savedQuery/${PATCH_BY_ENTITY}`, params );
  const deleteAlert = (id) => store.dispatch(`intelligence/savedQuery/${DELETE_BY_ID}`, id);
  const postByEntity = (params) => store.dispatch(`intelligence/savedQuery/${POST_BY_ENTITY}`, params);

  return {
    customerAlerts,
    shouldShowAlerts,
    savedQueryData,
    savedAlertErrors,
    selectedQueryId,
    queries,
    uniqueCustomers,
    getCustomerAlerts,
    getSavedAlertById,
    getAllSavedAlerts,
    setUniqueCustomers,
    patchByEntity,
    deleteAlert,
    postByEntity
  };
}