/* eslint-disable no-unused-vars */
import {
  phoneRegex,
  nameRegex,
  emailRegex,
  addressLineRegex,
  townRegex,
  postCodeRegex
} from '@/shared/regex/form';
import { salutationList } from '@/shared/constants/customerDetails/salutations';
import { countryList } from '@/shared/constants/countries/countries';

export const contactData = (compulsoryFields = [], nonCompulsoryFields = []) => {
  let fields = [
    {
      stage: 'Key Fields',
      fields: [
        {
          label: 'Customer',
          id: 'company',
          type: 'text',
          value: '',
          required: false,
          disabled: false,
        },
        {
          label: 'Title:',
          id: 'salutation',
          type: 'select',
          options: salutationList,
          value: '',
          required: true,
          disabled: false,
          errorMessage: 'Please select a title',
        },
        {
          label: 'First name:',
          id: 'first_name',
          type: 'text',
          min: 1,
          max: 40,
          placeholder: '',
          required: true,
          disabled: false,
          regex: nameRegex,
          errorMessage: 'Please enter a valid first name',
        },
        {
          label: 'Surname:',
          id: 'surname',
          type: 'text',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: true,
          disabled: false,
          regex: nameRegex,
          errorMessage: 'Please enter a valid last name',
        },
        {
          label: 'Preferred Name:',
          id: 'preferred_name',
          type: 'text',
          minLength: 1,
          maxLength: 80,
          placeholder: '',
          required: false,
          disabled: false,
          regex: nameRegex,
          errorMessage: 'Please enter a valid preferred name',
        },
        {
          label: 'Email:',
          id: 'email',
          type: 'email',
          min: 3,
          max: 50,
          placeholder: '',
          required: false,
          disabled: false,
          regex: emailRegex,
          errorMessage: 'Please enter a valid email',
        },
        {
          label: 'Mobile Phone:',
          id: 'mobile_phone',
          type: 'text',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          regex: phoneRegex,
          errorMessage: 'Please enter a valid mobile number',
        },
      ],
    },
    {
      stage: 'Secondary Fields',
      fields: [
        {
          label: 'Job Title:',
          id: 'job_title',
          type: 'text',
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          errorMessage: 'Please enter a valid job title',
        },
        {
          label: 'Business Phone:',
          id: 'business_phone',
          type: 'text',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          regex: phoneRegex,
          errorMessage: 'Please enter a valid business number',
        },
        {
          label: 'Office Phone:',
          id: 'office_phone',
          type: 'text',
          min: 1,
          max: 40,
          placeholder: '',
          required: false,
          disabled: false,
          regex: phoneRegex,
          errorMessage: 'Please enter a valid office number',
        },
        {
          label: 'Home Phone:',
          id: 'home_phone',
          type: 'text',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          regex: phoneRegex,
          errorMessage: 'Please enter a valid home number',
        },
        {
          label: 'Fax:',
          id: 'fax',
          type: 'text',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          regex: phoneRegex,
          errorMessage: 'Please enter a valid fax number',
        },
        {
          label: 'Default contact:',
          id: 'is_default_contact',
          type: 'switch',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          value: false
        },
        {
          label: 'Marketing contact:',
          id: 'is_marketing',
          type: 'switch',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          value: false
        },
        {
          label: 'Address line 1:',
          id: 'address_line_1',
          type: 'text',
          minLength: 1,
          maxLength: 80,
          placeholder: '',
          required: false,
          disabled: false,
          isAddress: true,
          regex: addressLineRegex,
          errorMessage: 'Please enter a valid Address line 1',
        },
        {
          label: 'Address line 2:',
          id: 'address_line_2',
          type: 'text',
          minLength: 1,
          maxLength: 80,
          placeholder: '',
          required: false,
          disabled: false,
          isAddress: true,
          regex: addressLineRegex,
          errorMessage: 'Please enter a valid Address line 2',
        },
        {
          label: 'Address line 3:',
          id: 'address_line_3',
          type: 'text',
          minLength: 1,
          maxLength: 80,
          placeholder: '',
          required: false,
          disabled: false,
          isAddress: true,
          regex: addressLineRegex,
          errorMessage: 'Please enter a valid Address line 3',
        },
        {
          label: 'Address line 4:',
          id: 'address_line_4',
          type: 'text',
          minLength: 1,
          maxLength: 80,
          placeholder: '',
          required: false,
          disabled: false,
          isAddress: true,
          regex: addressLineRegex,
          errorMessage: 'Please enter a valid Address line 4',
        },
        {
          label: 'Town:',
          id: 'town',
          type: 'text',
          minLength: 1,
          maxLength: 80,
          placeholder: '',
          required: false,
          disabled: false,
          isAddress: true,
          regex: townRegex,
          errorMessage: 'Please enter a valid town',
        },
        {
          label: 'County:',
          id: 'county',
          type: 'text',
          minLength: 1,
          maxLength: 40,
          placeholder: '',
          required: false,
          disabled: false,
          isAddress: true,
          regex: townRegex,
          errorMessage: 'Please enter a valid county',
        },
        {
          label: 'Post Code:',
          id: 'post_code',
          type: 'text',
          minLength: 1,
          maxLength: 12,
          placeholder: '',
          required: false,
          disabled: false,
          isAddress: true,
          regex: postCodeRegex,
          errorMessage: 'Please enter a valid post code',
        },
        {
          label: 'Country:',
          id: 'country',
          type: 'select',
          options: countryList,
          required: false,
          disabled: false,
          isAddress: true,
        },
        {
          label: 'Email',
          id: 'contact_preference_email',
          type: 'checkbox',
          value: false,
          required: false,
          disabled: false,
          isContact: true,
        },
        {
          label: 'Post',
          id: 'contact_preference_post',
          type: 'checkbox',
          value: false,
          required: false,
          disabled: false,
          isContact: true,
        },
        {
          label: 'Phone',
          id: 'contact_preference_phone',
          type: 'checkbox',
          value: false,
          required: false,
          disabled: false,
          isContact: true,
        },
        {
          label: 'SMS',
          id: 'contact_preference_sms',
          type: 'checkbox',
          value: false,
          required: false,
          disabled: false,
          isContact: true,
        },
      ],
    },
  ];

  compulsoryFields.forEach((field, fieldIndex) => {
    fields[0].fields[fieldIndex] = {
      ...fields[0].fields[fieldIndex],
      ...field,
    };
  });

  nonCompulsoryFields.forEach((field, fieldIndex) => {
    fields[1].fields[fieldIndex] = {
      ...fields[1].fields[fieldIndex],
      ...field,
    };
  });

  return fields;
};

export const customerContactData = contactData();
