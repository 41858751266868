import { 
  PATCH_ITEM, 
  POST_BY_ENTITY, 
  DELETE_ITEM, 
  DELETE_BY_ID, 
  SET_CONFIRMATION_MODAL, 
  GET_ALL, 
  GET_BY_ID,
  GET_WIDGET_BY_ID, 
  PATCH_BY_ENTITY,
  POST_ITEM,
  CLEAR_BY_ID,
  SET_UNSAVED_MODAL_STATE,
  SET_QUERY_STRING_CHANGED_STATE
} from '@/shared/store/actionType';
import { tCrm as t } from '@sales-i/utils';
import { computed, onMounted } from 'vue';
import { DEFAULT_DASHBOARD_CATEGORY, OTHER_DASHBOARD_CATEGORY } from '@/intelligence/components/Dashboard/constants';

export function useDashboard({ store, emit } = {}) {
  const categories = [
    { value: DEFAULT_DASHBOARD_CATEGORY, text: t('custom') },
    { value: 'customer', text: t('customer') },
    { value: 'performance', text: t('performance') },
    { value: 'enquiries', text: t('enquiries') },
    { value: 'products', text: t('products') },
    { value: OTHER_DASHBOARD_CATEGORY, text: t('other') },
  ];

  onMounted(async () => {
    if (store && !store.state.dashboards.all.loaded && !loading.value) await getDashboards();
  });

  const loading = computed(() => store.state.dashboards.all.loading);
  const dashboards = computed(() => store.state.dashboards.all.data);
  const selectedDashboard = computed(() => store.state.dashboards.selected.data);
  const deletedDashboard = computed(() => store.state.dashboards.selected.deleted);
  const showUnsavedChangesModal = computed(() => store.state.dashboards.showUnsavedChangesModal);
  const isQueryStringChanged = computed(() => store.state.dashboards.isQueryStringChanged);
  const widgets = computed(() => [...(selectedDashboard.value.widgets || [])].sort((a,b) => (a.order - b.order)));
   
  const postWidget = async (item) => store.dispatch(`dashboards/${POST_ITEM}`, item);
  const patchWidget = async (item) => store.dispatch(`dashboards/${PATCH_ITEM}`, item);
  const deleteWidget = async params => store.dispatch(`dashboards/${DELETE_ITEM}`, params);
  const setUnsavedChangesModal = (params) => store.dispatch(`dashboards/${SET_UNSAVED_MODAL_STATE}`, params);
  const setQueryStringChanged = (params) => store.dispatch(`dashboards/${SET_QUERY_STRING_CHANGED_STATE}`, params);
  const getDashboards = async params => await store.dispatch(`dashboards/${GET_ALL}`, params);
  const getDashboardById = async (item) => store.dispatch(`dashboards/${GET_BY_ID}`, item);
  const getWidgetById = async (item) => store.dispatch(`dashboards/${GET_WIDGET_BY_ID}`, item);
  const patchDashboard = async (item) => store.dispatch(`dashboards/${PATCH_BY_ENTITY}`, item);
  const postDashboard = async (item) => store.dispatch(`dashboards/${POST_BY_ENTITY}`, item);
  const deleteDashboard = async params => store.dispatch(`dashboards/${DELETE_BY_ID}`, params);
  const clearDashboard = async params => store.dispatch(`dashboards/${CLEAR_BY_ID}`, params);
  
  function validateTextField(value, min, max) {
    return value?.length >= min && value?.length <= max;
  }

  function getDashboardByCategory(category) {
    return dashboards.value.find(d => d.category == category);
  }

  const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);

  function showDeleteDashboardModal(item) {
    const { title } = item;
    showConfirmationModal({
      message: t('do_you_really_want_to_delete_dashboard_title', { title }),
      updatedMessage: t('you_have_deleted_dashboard_title', { title }),
      updateFunction: () => confirmDashboardDelete(item),
      finishFunction: finishDelete,
    });
  }

  async function confirmDashboardDelete(item) {
    const response = await deleteDashboard(item);
    if (response) emit('dashboardDeleted', item);
    return response;
  }

  function showDeleteWidgetModal(item) {
    const { name } = item;
    showConfirmationModal({
      message: t('do_you_really_want_to_delete_widget', { name }),
      updatedMessage: t('you_have_deleted_widget_name', { name }),
      updateFunction: () => confirmWidgetDelete(item),
      finishFunction: finishDelete,
    });
  }

  async function confirmWidgetDelete(item) {
    const response = await deleteWidget(item);
    if (response) emit('widgetDeleted', item);
    return response;
  }

  function finishDelete() {
    emit('closeModal');
    return true;
  }

  return {
    dashboards,
    widgets,
    categories,
    selectedDashboard,
    deletedDashboard,
    showUnsavedChangesModal,
    isQueryStringChanged,
    setUnsavedChangesModal,
    setQueryStringChanged, 
    validateTextField,
    getDashboardByCategory,
    postWidget,
    patchWidget,
    deleteWidget,
    getDashboards,
    getDashboardById,
    getWidgetById,
    postDashboard,
    patchDashboard,
    deleteDashboard,
    showDeleteDashboardModal,
    showDeleteWidgetModal,
    clearDashboard,
  };
}