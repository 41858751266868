<template>
  <CustomModal
    :title="props.title"
    show-modal
    :max-width="980"
    @close-modal="closeModal(true)"
  > 
    <template #title>
      <CustomButton
        :label="t('back')"
        icon-name="chevron-left"
        icon-color="white"
        small
        @click="handleBackButton"
      />
    </template>
    
    <div class="alert-builder-wizard flow">
      <router-view
        :date-params="dateParams"
        :date-filter="dateFilter"
        :from="props.from"
        :is-edit-mode="isEditMode"
        :is-first-sales-section="isFirstSalesSection"
        :is-first-crm-section="isFirstCrmSection"      
        :is-first-product-section="isFirstProductSection"
        :action-text="isEditMode ? t('edit') : t('add')"
        @valid="valid = $event"
        @refresh-count="refreshCount"
        @set-date="setDateParams"
        @update-alert-name="alertName = $event"
        @update-promo-material-link="promoMaterialLink = $event"
      />
    </div>

    <template #footer>
      <ButtonGroup
        v-if="!isEditMode && vroute.name !== NEW_ALERT_ROUTE"
        class="btn-group-bg"
      >
        <CustomButton
          v-if="vroute.path.includes('add-data')"
          purpose="action"
          @click="updateOrSaveAlert"
        >
          {{ t('no_thanks_i_m_done') }}
        </CustomButton>

        <CustomButton
          v-else-if="allDataEntered && !vroute.path.includes('name-alert')"
          :disabled="!valid"
          purpose="action"
          @click="goToNameAlert"
        >
          {{ t('i_m_done_save_alert') }}
        </CustomButton>

        <CustomButton
          v-else-if="vroute.path.includes('name-alert')"
          :disabled="!valid"
          purpose="action"
          @click="saveAlert"
        >
          {{ t('done') }}
        </CustomButton>

        <CustomButton
          v-else
          :disabled="!valid"
          purpose="action"
          @click="goToAddData"
        >
          {{ vroute.path.includes('product-data') ? t('add_these_products') : t('continue') }}
        </CustomButton>
      </ButtonGroup>

      <ButtonGroup
        v-if="isEditMode"
      >
        <CustomButton
          v-if="vroute.path.includes('add-data')"
          purpose="reversed"
          @click="goToNameAlert"
        >
          {{ t('save_as_new_alert') }}
        </CustomButton>
        <CustomButton
          v-if="vroute.path.includes('add-data')"
          purpose="action"
          @click="updateOrSaveAlert"
        >
          {{ t('save') }}
        </CustomButton>

        <CustomButton
          v-else-if="vroute.path.includes('name-alert')"
          :disabled="!valid"
          purpose="action"
          @click="saveAlert"
        >
          {{ t('done') }}
        </CustomButton>
        <CustomButton
          v-else
          purpose="action"
          :disabled="!valid"
          @click="goToAddData"
        >
          {{ t('save_edits') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script setup>
import { ref, computed, onMounted, } from 'vue';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import { CustomModal, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { DATE_FILTER_OPTIONS_V2 } from '@/intelligence/store/data/dateFilters';
import { alertBuilderScope, alertsScope } from '@/intelligence/router/urlBits';
import { dates, tCrm as t } from '@sales-i/utils';
import { NEW_ALERT_ROUTE } from '@/intelligence/router/routeNames';
import { QUERY_BUILDER } from '@/intelligence/store/data/reportTypes';
import { navigateToUrl } from 'single-spa';
import { useAlertBuilder } from '@/intelligence/composables/useAlertBuilder';
import useSavedQueries from '@/intelligence/composables/useSavedQueries';
import useShared from '@/intelligence/composables/useShared';
import useNotification from '@/shared/composables/useNotification';
import useEnquiries from '@/intelligence/composables/useEnquiries';

const store = useStore();
const vroute = useRoute();
const { 
  isEditMode, alertBuilderBaseUrl, alertBuilderModalBaseUrl, 
  crmFilters, salesFilters, allProducts,
  clearAllFilters, addQueryArgsToStore, clearProducts,
  isAlertBuilderVariance, requestDates,
} = useAlertBuilder({ store, vroute });
const { savedQueryData, savedAlertErrors, patchByEntity, postByEntity, getSavedAlertById } = useSavedQueries({ store });
const { getRequestParameters, getResponseParameters, fetchedData } = useShared({ store, vroute });
const { sendSuccessAlert } = useNotification({ store });
const { clearEnquiryFilters, resetAllDataClauses } = useEnquiries({ store });

const props = defineProps({
  title: {
    type: String,
    default:''
  }
});
const emit = defineEmits(['closeModal', 'refreshCount', 'alertSaved', 'setAlertDates']);

onMounted(async () => {
  await clearAll();
  if (isEditMode.value) {
    await getSavedAlertById(vroute.params.queryId);
    await addQueryArgsToStore();
    setDateParams(requestDates.value);
  }
});

const dateParams = ref({
  'date_from': 'today-3m',
  'date_to': 'today',
  'date_from2': 'today-3m-3m',
  'date_to2': 'today-3m',
});

const isFirstSalesSection = computed(() => salesFilters.value.length > 0);
const isFirstCrmSection = computed(() => crmFilters.value.length > 0 && !isFirstSalesSection.value);
const isFirstProductSection = computed(() => allProducts.value.length > 0 && !isFirstCrmSection.value);

// Match DATE_FILTER_OPTIONS_V2 text to the chosen date in dateParams
const dateFilter = computed(() => {
  const { date_from, date_to, date_from2, date_to2 } = dateParams.value;
  let filterOption = DATE_FILTER_OPTIONS_V2.find((option) => option.value === date_from);
  if (filterOption) return filterOption?.text || '';
  return isAlertBuilderVariance && date_from2 && date_to2
    ? `${dates.format(date_from)} - ${dates.format(date_to)} ― ${dates.format(date_from2)} - ${dates.format(date_to2)}` 
    : `${dates.format(date_from)} - ${dates.format(date_to)}`;
});

// If crmFilters, salesFilters, and allProducts all have some data empty, then
const allDataEntered = computed(() => 
  crmFilters.value.length > 0 && salesFilters.value.length > 0 && allProducts.value.length > 0
);

const valid = ref(false);
const alertName = ref('');
const promoMaterialLink = ref('');

function updateOrSaveAlert() {
  if (isEditMode.value) {
    updateAlert();
  } else {
    goToNameAlert();
  }
}

async function clearAll() {
  clearAllFilters();
  clearEnquiryFilters();
  clearProducts();
  resetAllDataClauses();
}

const filters = computed(() => {
  return {
    salesFilters: salesFilters.value,
    crmFilters: crmFilters.value,
    productFilters: allProducts.value,
    isUsingVarianceOutput: isAlertBuilderVariance.value,
  };
});

// Get the data clauses from the store in readines to pass along with the saveAlert
const getSalesDataClause = computed(() => store.state.intelligence.enquiry.salesDataClause);
const getCrmDataClause = computed(() => store.state.intelligence.enquiry.crmDataClause);
const getProductDataClause = computed(() => store.state.intelligence.enquiry.productDataClause);
const reportId = computed(() => Object.keys(fetchedData.value)[Object.keys(fetchedData.value).length - 1]);

const dataClauses = computed(() => {
  return {
    salesDataClause: getSalesDataClause.value,
    crmDataClause: getCrmDataClause.value,
    productDataClause: getProductDataClause.value,
  };
});

const queryParams = computed(() => getRequestParameters(QUERY_BUILDER));

const uniqueCustomers = computed(() => {
  const arr = [];
  const reportData = fetchedData.value[reportId.value]?.data;
  if (reportData) {
    const { rows } = reportData;
    let n = 0;
    if (rows !== undefined) {
      for (n; n < rows.length; n++) {
        const entityId = rows[n].dimensions[0].entity_id;
        if (arr.indexOf(entityId) === -1) {
          arr.push(entityId);
        }
      }
    }
  }
  return arr;
});

async function saveAlert() {
  if (alertName.value === '') return;
  await postByEntity({
    entity_type: 'community',
    entity_id: 1,
    query_name: `${alertName.value}|~~|${promoMaterialLink.value}`,
    results: [
      {
        query_arguments: `querybuilder (${queryParams.value})||||${JSON.stringify(filters.value)}~~~~${JSON.stringify(dataClauses.value)}`,
        query_fields: getResponseParameters(QUERY_BUILDER.replace('-', '')),
        results: JSON.stringify(uniqueCustomers.value),
      },
    ],
  });
  if (savedAlertErrors.value.length === 0) {
    alertName.value = '';
    sendSuccessAlert(t('successfully_saved_alert'));
    emit('alertSaved');
    closeModal();
  }
}

async function updateAlert() {
  await patchByEntity([
    savedQueryData.value.id,
    {
      id: savedQueryData.value.results[0].id,
      query_arguments: `querybuilder (${queryParams.value})||||${JSON.stringify(filters.value)}~~~~${JSON.stringify(dataClauses.value)}`,
      results: JSON.stringify(uniqueCustomers.value),
    }
  ]);
  if (savedAlertErrors.value.length === 0) {
    alertName.value = '';
    emit('alertSaved');
    sendSuccessAlert(t('successfully_saved_alert'));
  }
  closeModal();
}

const setDateParams = (value) => {
  dateParams.value = {
    ...dateParams.value,
    ...value,
  };
  emit('setAlertDates', dateParams.value);
};

function handleBackButton() {
  return history.back();
}

function refreshCount() {
  emit('refreshCount');
}

function goToAddData() {
  // Determine the source page and add the corresponding query parameter
  let sourcePage = '';
  if (vroute.path.includes('sales-data')) {
    sourcePage = t('sales');
  } else if (vroute.path.includes('crm-data')) {
    sourcePage = t('c_r_m');
  } else if (vroute.path.includes('product-data')) {
    sourcePage = t('product');
  }
  // Go to the /sales-data route and include an optional parameter for queryId. If there's no id then don't render that portion of the URL
  let path = `${alertBuilderModalBaseUrl.value}/add-data`;
  let query = new URLSearchParams({ sourcePage });
  navigateToUrl(`${path}?${query.toString()}`);
}

function goToNameAlert() {
  navigateToUrl(`${alertBuilderBaseUrl.value}/${alertsScope}/${alertBuilderScope}/name-alert`);
}

function closeModal(showConfirmation = false) {
  emit('closeModal', showConfirmation);
}
</script>

<style lang="scss">
// Styles that are intentionally un-scoped so the children can inherit the styles (wrapperd in a unique class for safety)
.alert-builder-wizard {

  .intro-wrapper {
    display: grid;
    justify-items: center;
    margin-inline: auto;
    margin-bottom: var(--spacing-5);
    max-width: 52rem;
    padding-inline: var(--spacing-6);
    text-align: center;

    p {
      margin-block-end: 0;
    }
  }

  .form-field-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: var(--spacing-4);

    .form-group {
      margin-bottom: 0;
    }

    .button {
      padding: 0;
    }
  }
  
  .form-field-flex {
    display: flex;
    gap: var(--spacing-3);
    align-items: center;
  }

  .delete-icon {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--spacing-6);
  }

  .add-button {
    background-color: var(--colour-utility-white);
    display: grid;
    grid-template-areas: "main";
    margin-bottom: var(--spacing-10);

    .menu-dropdown {
      margin: var(--spacing-2) 0 var(--spacing-2) 0;
      grid-area: main;
      place-self: end;

      .list {
        background-color: var(--colour-utility-black);

        .list-item__title {
          color: var(--colour-utility-white);
        }
      }
    }

    .icon-button {
      font-size: var(--font-size-4);
      font-weight: var(--font-weight-medium);
      
      span {
        margin-left: var(--spacing-2);
      }
    }

    &::before {
      content: '';
      width: 100%;
      border: 1px solid var(--colour-panel-g-8);
      grid-area: main;
      place-self: center;
    }
  }

  .list .list-item:hover {
    background-color: var(--colour-panel-g-72);
    min-width: var(--spacing-12);
  }
}
</style>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .alert-builder-wizard {
    padding: var(--spacing-2);
  }
}

:deep(.top) {
  margin-top: calc(var(--spacing-3) * -1);
}

:deep(.header .button.small) {
  padding: var(--spacing-1) var(--spacing-2) var(--spacing-1) var(--spacing-1);
  margin: 0 0 var(--spacing-2) 0;
}

.btn-group-bg {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
