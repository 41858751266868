<template>
  <svg
    :width="svgSize"
    :height="svgSize"
    viewBox="0 0 58 58"
    fill="none"
  >
    <!-- Dashed green line -->
    <circle
      cx="29"
      cy="29"
      r="26"
      stroke="var(--colour-data-de-york-dark)"
      stroke-width="0.6"
      stroke-dasharray="2 1"
    />
    <!-- Red path -->
    <path
      :d="arcPath"
      stroke="var(--colour-brand-mandy)"
      stroke-width="2"
      stroke-linecap="round"
    />
    <!-- Text in the center -->
    <text
      x="30"
      y="30"
      text-anchor="middle"
      alignment-baseline="middle"
      fill="black"
      font-size="10"
    >
      <tspan
        x="29"
        :font-size="textSize"
      >
        <slot />
      </tspan>
      <tspan
        dy="6"
        x="28"
        :font-size="textSizeSubtext"
      >
        <slot name="subText" />
      </tspan>
    </text>
  </svg>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  currentValue: {
    type: Number,
    default: 0,
  },
  totalValue: {
    type: Number,
    default: 1,
  },
  remainingValue: {
    type: Number,
    default: 1,
  },
  svgSize: {
    type: Number,
    default: 54,
  },
  textSize: {
    type: Number,
    default: 10,
  },
  textSizeSubtext: {
    type: Number,
    default: 10,
  },
});


const percentageAchieved = computed(() => {
  const total = props.totalValue || 1; // Prevent division by zero
  return Math.round((props.currentValue / total) * 100);
});

const endAngle = computed(() => {
  return (percentageAchieved.value / 100) * 360;
});

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
};

const describeArc = (x, y, radius, startAngle, endAngle) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
  return [
    'M', start.x, start.y,
    'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
  ].join(' ');
};

const arcPath = computed(() => describeArc(29, 29, 26, 180, 180 +  endAngle.value));
</script>

<style lang="scss" scoped>
.donut {
  width: 54px;
  height: 54px;
  background: 
    radial-gradient(white 56%, var(--colour-data-de-york-dark) 57%);
  border-radius: 50%;
  transform: rotate(180deg);
}

.donut::after {
  content: "";
  position: absolute;
  background:
    radial-gradient(white 55%, transparent 56%),
    conic-gradient(var(--colour-brand-mandy) 0% 57%, transparent 0% 0%);
  width: 54px;
  height: 54px;
  border-radius: 50%;
}</style>