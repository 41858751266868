<template>
  <CRMCard
    :title="props.item[0].prod_group_level_1_desc"
    :links="getLinks(props.item[0])"
    @pursue="pursueAsInteraction"
    @alert-builder="goToAlertBuilder"
    @dismiss="dismissGap"
  >
    <template #info>
      <p>{{ t('customer_has_not_purchased_from_these_product_groups') }}</p>
      <table>
        <tbody>
          <tr
            v-for="subItem in props.item"
            :key="subItem.id"
          >
            <td>
              <strong>{{ subItem.prod_group_level_2_desc }}</strong>
            </td>
            <td class="checkbox">
              <Field
                :value="getSelectionStatus(subItem)"
                type="checkbox"
                @input="$event => manageSelection($event, subItem)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </CRMCard>

  <DismissModal
    v-if="dismissModalOpen"
    :dismiss-modal-open="dismissModalOpen"
    :close-dismiss-modal="closeDismissModal"
    :dismiss-options="dismissOptions"
    object-type="association"
    @post-feedback="deleteProductGroupGap"
  />
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { SET_FURTHER_DETAILS } from '@/crm/store/actionType';
import { useRoute } from 'vue-router';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';
import { baseUrl as ibaseUrl, alertsScope } from '@/intelligence/router/urlBits';
import { GET_BY_ID, DELETE_BY_ID, SET_ENTITY, SET_ITEMS } from '@/shared/store/actionType';
import { tCrm as t } from '@sales-i/utils';
import Field from '@/shared/components/Form/Field.vue';
import DismissModal from '@/admin/components/AiFeedback/DismissModal.vue';
import { CRMCard } from '@sales-i/dsv3';
import { navigateToUrl } from 'single-spa';

const props = defineProps({
  item: {
    type: Array,
    default: () => [],
  }
});

const store = useStore();
const vroute = useRoute();

const emit = defineEmits(['reloadGaps']);

const errors = computed(() => store.getters['intelligence/productGroups/getErrors']);
const customer = computed(() => store.state.crm.customers.selected);
const getCustomer = params => store.dispatch(`crm/customers/${GET_BY_ID}`, params);
const deleteCustomerGaps = (id) => store.dispatch(`intelligence/productGroups/${DELETE_BY_ID}`, id);
const setEntity = params => store.dispatch(`crm/interactions/${SET_ENTITY}`, params);
const setFurtherDetails = params => store.dispatch(`crm/interactions/${SET_FURTHER_DETAILS}`, params);

const selections = ref([]);
const dismissModalOpen = ref(false);
const dismissOptions = ref([]);

const closeDismissModal = () => {
  dismissModalOpen.value = false;
};

const getLinks = (item) => {
  return [
    {
      text: t('dismiss'),
      id: item.id,
      emit: 'dismiss',
    },
    {
      text: t('view_in_alert_builder'),
      id: item.id,
      emit: 'alert-builder',
    },
    {
      text: t('pursue_as_interaction'),
      id: item.id,
      emit: 'pursue',
    },
  ];
};

const dismissGap = () => {
  let selectedOptions = getAllActiveSelections();
  if (selectedOptions.length === 0) {
    selectedOptions = props.item;
  }
  
  let n = 0;
  for (n; n < selectedOptions.length; n++) {
    dismissOptions.value.push({
      object: 'product_group_gap',
      object_id: selectedOptions[n].prod_group_level_2,
    });
  }
  
  dismissModalOpen.value = true;
};

const deleteProductGroupGap = async () => {
  let selectedOptions = getAllActiveSelections();
  if (selectedOptions.length === 0) {
    selectedOptions = props.item;
  }

  let n = 0;
  let promises = [];
  for (n; n < selectedOptions.length; n++) {
    promises.push(deleteCustomerGaps(selectedOptions[n].id));
  }

  await Promise.all(promises);

  if (errors.value.length > 0) {
    store.dispatch(`alerts/${SET_ITEMS}`, {
      type: 'error',
      message: t('failed_to_dismiss_product_group_gaps'),
      selfDismiss: true,
    });
  } else {
    store.dispatch(`alerts/${SET_ITEMS}`, {
      type: 'success',
      message: t('product_group_gaps_successfully_dismissed'),
      selfDismiss: true,
    });

    closeDismissModal();
    emit('reloadGaps');
  }
};

const manageSelection = ($event, subItem) => {
  if ($event === false) {
    selections.value = selections.value.filter(o => o !== subItem.id);
  } else {
    selections.value.push(subItem.id);
  }
};

const getSelectionStatus = (item) => {
  return selections.value.findIndex(o => o === item.id) !== -1;
};

const getAllActiveSelections = () => {
  const fullItems = [];
  let n = 0;
  for (n; n < selections.value.length; n++) {
    const _ref = selections.value[n];
    const activeItem = props.item.find(o => o.id === _ref);
    if (activeItem !== undefined) {
      fullItems.push(activeItem);
    }
  }

  return fullItems;
};

const getAllActiveSelectionNames = () => {
  const fullItems = [];
  let n = 0;
  for (n; n < selections.value.length; n++) {
    const _ref = selections.value[n];
    const activeItem = props.item.find(o => o.id === _ref);
    if (activeItem !== undefined) {
      fullItems.push(activeItem.prod_group_level_2_desc);
    }
  }

  return fullItems;
};

const goToAlertBuilder = () => {
  let selected = getAllActiveSelections();
  if (selected.length === 0) {
    selected = props.item;
  }

  // Build a list of products to give to the URL
  const searchParams = new URLSearchParams();
  let n = 0;
  for (n; n < selected.length; n++) {
    const _ref = selected[n];

    searchParams.append('product', `pg-${_ref.prod_group_level_1}-${_ref.prod_group_level_2}||${_ref.prod_group_level_2_desc}`);
  }

  navigateToUrl(`${ibaseUrl}/${alertsScope}/product-search?${searchParams.toString()}`);
};

const pursueAsInteraction = async () => {
  let activeSelection = getAllActiveSelectionNames();
  let interactionNote = '';

  if (activeSelection.length === 0) {
    props.item.forEach(o => activeSelection.push(o.prod_group_level_2_desc));
  }

  interactionNote = activeSelection.join(', ');

  await getCustomer({ id: vroute.params.id });
  setEntity({
    entityType: 'customer',
    entity: {
      ...customer.value.data,
      header: customer.value.data.name,
      subHeader: customer.value.data.account_number,
    },
  });

  setFurtherDetails({
    interactionNote: `Follow up to this customer not purchasing product group level 2: ${interactionNote}`,
  });

  // Push them to the interactions
  navigateToUrl(`${baseUrl}/${interactionsArea}/add?clean=false`);
};

</script>

<style lang="scss" scoped>

table {
  width: 100%;

  td {
    border: none;
  }

  .checkbox {
    text-align: right;
  }
}

</style>