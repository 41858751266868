import { dates } from '@sales-i/utils';

export const getRequestParameters = () => ([reportType, params]) => {
  const {
    selectedAnalysisField = {},
    selectedProperties = [],
    selectedDate = {},
    selectedMeasurement,
    targetAmount,
    // year = new Date().getFullYear(),
    offset = 0,
    limit = 20,
    // sortHeader = 'name',
    // sortOption = 'asc',
    targetId,
    bubbleId,
  } = params;

  const analysis_field_id = selectedAnalysisField?.analysis_field_id ?? '';
  const area = selectedAnalysisField?.area ?? '';
  const title = selectedAnalysisField?.title ?? '';
  const propertyValues = selectedProperties.map(property => property.code);
  const type = 'analysis_field';
  const interval = selectedDate.interval;
  const intervalMultiplier = selectedDate.intervalMultiplier;
  const startDate = dates.forReportApi(selectedDate.startDate);
  const endDate = dates.forReportApi(selectedDate.endDate);
  const measurement = selectedMeasurement ?? '';
  const measurementValue = targetAmount ?? 0;

  const generateInput = () => `
  input: {
    ${targetId ? `id: ${targetId}` : ''}
    analysis_fields: [
      {
        analysis_field_id: ${analysis_field_id}
        area: "${area}"
        title: "${title}"
        value_codes: ${JSON.stringify(propertyValues)}
        type: "${type}"
      }
    ]
    target_period: {
      interval: ${interval}
      intervalMultiplier: ${intervalMultiplier}
      startDate: "${startDate}"
      endDate: "${endDate}"
    }
    target_value: {
      measurement: "${measurement}"
      values: [${measurementValue}]
    }
  }
`;

  switch (reportType) {
  case 'get':
    return `
      filter: {}
      pagination: {
        offset: ${offset}, 
        limit: ${limit}
      }
    `;
  case 'add':
  case 'update':
    return generateInput(params);
  case 'delete':
    return `id: ${targetId}`;
  case 'deleteTargetsByBubbleId':
    return `bubbleId: ${bubbleId}`;
  case 'getTargetPeriod': 
    return `
      interval: ${params.interval}
      intervalMultiplier: ${params.intervalMultiplier}
      startDate: "${params.startDate ? dates.forReportApi(params.startDate) : ''}"
      endDate: "${params.endDate ? dates.forReportApi(params.endDate) : ''}"
    `;
  }
};