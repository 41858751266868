import {
  GET_CALENDAR,
  GET_CALENDAR_LOADING,
  GET_CALENDAR_ERROR,
  PUT_PRIMARY_CALENDAR,
  PUT_PRIMARY_CALENDAR_LOADING,
  PUT_PRIMARY_CALENDAR_ERROR,
  DELETE_CALENDAR,
  DELETE_CALENDAR_LOADING,
  DELETE_CALENDAR_ERROR
} from '@/admin/store/actionType/index';
import { httpRequest } from '@sales-i/utils';

// getters
export const calendarGetters = {};

// mutations
export const calendarMutations = {
  [GET_CALENDAR]: (state, calendar) => {
    state.calendar.data = calendar;
    state.calendar.loading = false;
    state.calendar.error = false;
  },
  [GET_CALENDAR_LOADING]: (state) => {
    state.calendar.loading = true;
    state.calendar.error = false;
  },
  [GET_CALENDAR_ERROR]: (state) => {
    state.calendar.loading = false;
    state.calendar.error = true;
  },
  [PUT_PRIMARY_CALENDAR]: (state) => {
    state.calendar.loading = false;
    state.calendar.error = false;
  },
  [PUT_PRIMARY_CALENDAR_LOADING]: (state) => {
    state.calendar.loading = true;
    state.calendar.error = false;
  },
  [PUT_PRIMARY_CALENDAR_ERROR]: (state) => {
    state.calendar.loading = false;
    state.calendar.error = true;  
  },
  [DELETE_CALENDAR]: (state) => {
    state.calendar.data = []; 
    state.calendar.loading = false;
    state.calendar.error = false;
  },
  [DELETE_CALENDAR_LOADING]: (state) => {
    state.calendar.loading = true;
    state.calendar.error = false;
  },
  [DELETE_CALENDAR_ERROR]: (state) => {
    state.calendar.loading = false;
    state.calendar.error = true;
  },
};

export const calendarActions = {
  [GET_CALENDAR]: async ({ commit }) => {
    commit(GET_CALENDAR_LOADING);
    try {
      const calendarData = await httpRequest('get', 'nylas/calendar', {}, {
        area: 'ecisync',
        throwErrors: false,
        sanitize: false
      });
      const calendar = calendarData.data || [];
      commit(GET_CALENDAR, calendar);
      return calendarData;
    } catch (e) {
      commit(GET_CALENDAR_ERROR, e);
    }
  },
  [PUT_PRIMARY_CALENDAR]: async ({ commit }, calendarId) => {
    commit(PUT_PRIMARY_CALENDAR_LOADING);
    try {
      await httpRequest('put', `nylas/calendar/${calendarId}`, {}, {
        area: 'ecisync',
        throwErrors: false,
        sanitize: false
      });
      commit(PUT_PRIMARY_CALENDAR);
    } catch (e) {
      commit(PUT_PRIMARY_CALENDAR_ERROR, e);
    }
  },
  [DELETE_CALENDAR]: async ({ commit }) => {
    commit(DELETE_CALENDAR_LOADING);
    try {
      await httpRequest('delete', 'nylas/calendar', {}, {
        area: 'ecisync',
        throwErrors: false,
        sanitize: false
      });
      commit(DELETE_CALENDAR);
    } catch (e) {
      commit(DELETE_CALENDAR_ERROR, e);
    }
  },
};
  