<template>
  <div class="filter">
    <div class="filter-type">
      <TabSwitch
        v-if="showTabSwitch"
        class="tab-switch"
        :items="AND_OR_OPTIONS"
        :selected-value="selectedValue"
        @switch-selected="handleFilterSwitch($event)"
      />
      <slot />
    </div>

    <div class="actions">
      <IconButton
        icon-name="edit"
        purpose="transparent"
        icon-color="var(--colour-utility-action)"
        variant="round"
        :icon-size="24"
        @click="openEditAlertModal(selectedQueryId)"
      />
      <IconButton
        v-if="showDeleteIcon"
        icon-name="trash"
        purpose="transparent"
        icon-color="var(--colour-utility-action)"
        variant="round"
        :icon-size="24"
        @click="removeFilter(id, filterSection, entity, productType)"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { IconButton, TabSwitch } from '@sales-i/dsv3';
import { tCrm as t } from '@sales-i/utils';
import { QUERY_BUILDER } from '@/intelligence/store/data/reportTypes';
import { alertBuilderScope, alertsScope } from '@/intelligence/router/urlBits';
import { navigateToUrl } from 'single-spa';
import { AND_OR_OPTIONS } from '@/intelligence/store/data/filters';
import { useAlertBuilder } from '@/intelligence/composables/useAlertBuilder';
import useSavedQueries from '@/intelligence/composables/useSavedQueries';
import useShared from '@/intelligence/composables/useShared';
import useSystem from '@/shared/composables/useSystem';

const store = useStore();

const props = defineProps({
  clause: {
    type: String,
    default: '',
  },
  operator: {
    type: String,
    default: '',
  },
  entity: {
    type: String,
    default: '',
  },
  filterSection: {
    type: String,
    default: '',
  },
  id: {
    type: Number,
    default: 0,
  },
  index: {
    type: Number,
    default: 0,
  },
  productType: {
    type: String,
    default: '',
  },
  showDeleteIcon: {
    type: Boolean,
    default: true,
  },
  showTabSwitch: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: '',
  },
});

const { alertBuilderBaseUrl, updateCustomerFilterInQuery, 
  updateSalesFilterInQuery, updateProductFilterInQuery,
  removeSalesFilterFromQuery, removeCustomerFilterFromQuery, removeProductFromQuery,
  crmFilters, salesFilters, allProducts } = useAlertBuilder({ store });
const { selectedQueryId, savedQueryData, patchByEntity } = useSavedQueries({ store });
const { showConfirmationModal } = useSystem({ store });
const { getRequestParameters } = useShared({ store });

const selectedValue = computed(() => props.type || props.clause || props.operator);

// Get the data clauses from the store in readines to pass along with the saveAlert
const getSalesDataClause = computed(() => store.state.intelligence.enquiry.salesDataClause);
const getCrmDataClause = computed(() => store.state.intelligence.enquiry.crmDataClause);
const getProductDataClause = computed(() => store.state.intelligence.enquiry.productDataClause);

const filters = computed(() => {
  return {
    salesFilters: salesFilters.value,
    crmFilters: crmFilters.value,
    productFilters: allProducts.value,
  };
});

const dataClauses = computed(() => {
  return {
    salesDataClause: getSalesDataClause.value,
    crmDataClause: getCrmDataClause.value,
    productDataClause: getProductDataClause.value,
  };
});

function openEditAlertModal(selectedQueryId) {
  const alertBuilerPath = `${alertBuilderBaseUrl.value}/${alertsScope}/${selectedQueryId}/${alertBuilderScope}`;
  // Route the user to the first page in the alertbuilder they have data for
  if (props.filterSection === 'sales'){
    navigateToUrl(`${alertBuilerPath}/sales-data`);
  } else if (props.filterSection === 'crm') {
    navigateToUrl(`${alertBuilerPath}/crm-data`);
  } else if (props.filterSection === 'products') {
    navigateToUrl(`${alertBuilerPath}/product-data`);
  }
}

async function removeFilter(id, filterSection, entity) {
  const removeFunctions = {
    'sales': async () => await removeSalesFilterFromQuery(id),
    'crm': async () => await removeCustomerFilterFromQuery({ id, entity }),
    'products': async () => await removeProductFromQuery({ id })
  };
  const removeFunction = removeFunctions[filterSection];
  if (!removeFunction) {
    console.error('Invalid filter section provided.');
    return;
  }
  showConfirmationModal({
    message: t('do_you_really_want_to_delete_this_filter'),
    updatedMessage: t('you_have_deleted_this_filter'),
    updateFunction: async () => {
      try {
        await removeFunction();
        updateFilters();
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  });
}

async function handleFilterSwitch($event) {
  if (props.filterSection === 'crm') {
    await updateCustomerFilterInQuery({
      id: props.id,
      clause: $event.value,
    });
  } else if (props.filterSection === 'sales') {
    await updateSalesFilterInQuery({
      id: props.id,
      type: $event.value,
    });
  } else {
    await updateProductFilterInQuery({
      id: props.id,
      operator: $event.value,
    });
  }
  await new Promise(resolve =>
    setTimeout(resolve, 1000)
  );
  updateFilters();
}

async function updateFilters() {
  // Get the latest filters from the store
  const queryParams = getRequestParameters(QUERY_BUILDER);
  await patchByEntity([
    savedQueryData.value.id,
    {
      id: savedQueryData.value.results[0].id,
      query_arguments: `querybuilder (${queryParams})||||${JSON.stringify(filters.value)}~~~~${JSON.stringify(dataClauses.value)}`,
    }
  ]);
}
</script>

<style lang="scss" scoped>
.filter {
  --flow-space: var(--spacing-3);
  background-color: var(--colour-panel-alert-base);
  border-radius: var(--border-radius-1);
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-2);
  justify-content: space-between;
  padding: var(--spacing-1) var(--spacing-2) var(--spacing-1) var(--spacing-3);
  // padding-top: var(--spacing-3);

  p {
    font-size: var(--font-size-4);
  }
}

.filter-type {
  align-self: center;
}

.filter-type:has(.tab-switch) {
  padding-top: var(--spacing-3);
} 


:deep(.tab-switch) {
  position: absolute;
  margin-top: calc(var(--spacing-5) * -1);

}
.actions {
  display: flex;
}
</style>
