<template>
  <div class="date-range">
    <div class="description">
      <h3>{{ t('custom_date_range') }}</h3>
      <div>
        <span v-if="dateRangeNumber > 1"> {{ activeDateRange }}: </span>
        <IconBase
          icon-name="calendar"
          :height="48"
          :width="48"
        />
      </div>
      <h4>
        {{ t('set_date_range') }}
        {{ isSecondDateRangeAvailable ? activeDateRange : '' }}
      </h4>
    </div>
    <div class="bar">
      <div
        v-for="(step, i) in dateRangeNumber"
        :key="step"
        :class="{ active: i === 0 || isSecondDateRangeVisible }"
      />
    </div>
    <IconButton
      class="return"
      icon-name="arrow-left"
      icon-color="var(--colour-utility-action)"
      purpose="transparent"
      icon-only
      :icon-size="32"
      @click.stop="hideDateRange"
    />
    <template v-if="!isSecondDateRangeVisible">
      <label for="date_from">
        {{ t('date') }}
        <span v-if="isSecondDateRangeAvailable">1</span>
        ({{ t('from') }})
        <input
          id="date_from"
          v-model="date_from"
          type="date"
          :max="date_to !== '' ? date_to : null"
          class="mt-1"
        >
      </label>
      <label for="date_to">
        {{ t('date') }}
        <span v-if="isSecondDateRangeAvailable">1</span>
        ({{ t('to') }})
        <input
          id="date_to"
          v-model="date_to"
          type="date"
          :min="date_from"
          class="mt-1"
        >
      </label>
    </template>
    <template v-else>
      <label for="date_from2">
        <p>{{ t('date') }} 2 ({{ t('from') }})</p>
        <input
          v-model="date_from2"
          type="date"
          name="date_from2"
          :max="date_to2"
        >
      </label>
      <label for="date_to2">
        <p>{{ t('date') }} 2 ({{ t('to') }})</p>
        <input
          v-model="date_to2"
          type="date"
          name="date_to2"
          :min="date_from2"
        >
      </label>
    </template>
    <div
      v-if="showSubmit"
      class="submit-container"
    >
      <CustomButton
        class="submit"
        action="submit"
        :label="dateRangeSubmitLabel"
        @click="dateRangeSubmit"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { IconButton, CustomButton, IconBase } from '@sales-i/dsv3';
import { absDateRegex } from '@/shared/regex/datepicker';
import { tCrm as t, dates } from '@sales-i/utils';
import useNotification from '@/shared/composables/useNotification';

const props = defineProps({
  dateRangeNumber: {
    type: Number,
    default: 1,
  },
});

const emit = defineEmits(['hideDateRange', 'setActiveDate']);
const { sendErrorAlert } = useNotification();

const date_from = ref('');
const date_to = ref('');
const date_from2 = ref('');
const date_to2 = ref('');
const isSecondDateRangeVisible = ref(false);
const activeDateRange = ref(1);

const datesObject = computed(() => ({
  date_from: date_from.value,
  date_to: date_to.value,
}));

const dateRangeSubmitLabel = computed(() => 
  `${t('set_date_range')} ${isSecondDateRangeAvailable.value ? activeDateRange.value : ''}`
);

const showSubmit = computed(() => 
  activeDateRange.value === 1
    ? absDateRegex.test(date_from.value) && absDateRegex.test(date_to.value)
    : absDateRegex.test(date_from2.value) && absDateRegex.test(date_to2.value)
);

const isSecondDateRangeAvailable = computed(() => props.dateRangeNumber === 2);

function hideDateRange() {
  emit('hideDateRange');
}

function dateRangeSubmit() {
  const dates = { ...datesObject.value };

  if (isSecondDateRangeAvailable.value && activeDateRange.value === 1) {
    if (!validateDates(dates)) return;

    activeDateRange.value += 1;
    isSecondDateRangeVisible.value = true;
    return;
  }

  if (isSecondDateRangeVisible.value) {
    dates.date_from2 = date_from2.value;
    dates.date_to2 = date_to2.value;
  } else {
    const yearFrom = Number(date_from.value.slice(0, 4)) - 1;
    const yearTo = Number(date_to.value.slice(0, 4)) - 1;
    dates.date_from2 = yearFrom + dates.date_from.slice(4);
    dates.date_to2 = yearTo + dates.date_to.slice(4);
  }

  if (validateDates(dates)) {
    emit('setActiveDate', dates);
  }
}

function validateDates(dateRanges) {
  if ((dates.getDateTime(dateRanges.date_from) > dates.getDateTime(dateRanges.date_to) ||
    dates.getDateTime(dateRanges.date_from2) > dates.getDateTime(dateRanges.date_to2))) {
    sendErrorAlert(t('please_correct_your_date_filter_date_from_can_not_be_after_date_to'));
    return false;
  }
  return true;
}
</script>

<style lang="scss" scoped>
.date-range {
  width: 100%;
  display: flex;
  flex-direction: column;
}

label {
  flex-basis: 100%;
  text-align: left;
  margin-bottom: var(--spacing-3);
}

p {
  color: var(--colour-panel-g-64);
  font-size: var(--font-size-5);
  letter-spacing: 0;
  margin-bottom: var(--spacing-1);
  display: inline-block;
}

.return.icon-button.transparent {
  position: absolute;
  left: var(--spacing-2);
  top: var(--spacing-2);

  &:deep(.button-container) {
    // temp/demo TODO: if no title for IconButton than margin should not be there. Solution: move margin from div to span in component
    margin-left: 0;
  }
}

.submit-container {
  background: var(--colour-panel-action);
  padding: var(--spacing-2);
  margin-left: calc(-1 * var(--spacing-2));
  margin-right: calc(-1 * var(--spacing-2));
}

.submit {
  padding: var(--spacing-1) 0;
  width: 100%;
}

.bar {
  display: flex;
  gap: var(--spacing-1);
  margin-bottom: var(--spacing-3);

  > div {
    height: var(--spacing-1);
    background: var(--colour-panel-g-16);
    flex: 1;

    &.active {
      background: var(--colour-data-puerto-rico);
    }
  }
}

.description {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: var(--spacing-4) 0;

  h3,
  h4 {
    font-weight: var(--font-weight-semibold);
    flex-basis: 100%;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
      font-size: var(--font-size-3);
      font-weight: var(--font-weight-semibold);
      color: var(--colour-utility-black);
      margin-bottom: 0;
    }
  }
}
</style>
