<template>
  <div class="customer-contact-details">
    <dl :class="`detail-grid mb-2 flow ${mq}`">
      <div
        v-for="(item, index) in displayFields"
        :key="index"
        class="dl-wrap flow"
      >
        <dt v-if="item.label !== 'Address' || !noAddress">
          {{ t(item.label) }}:
        </dt>
        <dd v-if="/email/iu.test(item.label)">
          <a :href="getMailtoLink(item.value)">{{ item.value }}</a>
        </dd>
        <dd v-else-if="/telephone/iu.test(item.label)">
          <a :href="`tel:${item.value}`">{{ item.value }}</a>
        </dd>
        <dd v-else-if="/created/iu.test(item.label)">
          {{ dates.format(item.value) }}
        </dd>
        <dd
          v-else-if="/web/iu.test(item.label)"
          class="website"
        >
          <a
            :href="item.value"
            target="_blank"
            rel="noopener noreferrer"
          >{{ item.value }}</a>
        </dd>
        <dd v-else-if="item.label === 'Address'">
          <Address
            :key="index"
            :value="item.value"
          />
        </dd>
        <dd v-else>
          {{ item.value }}
        </dd>
      </div>
    </dl>
    <CustomerAnalysisFields
      v-if="customer?.analysis_fields?.length"
      :analysis-fields="customer.analysis_fields"
      :prospect-id="customer.id"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useMq } from 'vue3-mq';
import mapCustomerContactDetails from '@/shared/utils/mapCustomerContactDetails';
import Address from '@/shared/components/Map/Address.vue';
import { dates, t } from '@sales-i/utils';
import CustomerAnalysisFields from './CustomerAnalysisFields.vue';

// Props
const props = defineProps({
  customer: {
    type: Object,
    default: () => ({}),
  },
  noAddress: {
    type: Boolean,
    default: false,
  },
});

const mq = useMq();

// Computed properties
const displayFields = computed(() => mapCustomerContactDetails(props.customer));

const getMailtoLink = (email) => `mailto:${email}`;
</script>

<style lang="scss" scoped>
.customer-contact-details {
  .md,
  .lg,
  .xl {
    .customer-contact-details {
      padding-left: var(--spacing-3);
      padding-right: var(--spacing-3);
    }
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// To layout the telephone and fax numbers horizontally
.detail-grid > *:nth-child(2),
.detail-grid > *:nth-child(3),
.detail-grid > *:nth-child(4) {
  display: inline-block;
  padding-right: var(--spacing-4);
}

// Adjust the flow space between the description list items
.dl-wrap {
  --flow-space: var(--spacing-3);
}

// Adjust the flow space between the description title and definition
.dl-wrap > * {
  --flow-space: var(--spacing-half);
}

dt {
  font-size: var(--font-size-small);
}

dd a {
  font-weight: var(--font-weight-semibold);
}
</style>
