import {
  GET_BY_ID,
  GET_BY_ID_LOADING,
  GET_BY_ID_ERROR,
  CLEAR_BY_ID,
} from '@/shared/store/actionType';
import { CLEAR_CURRENTLY_EDITING } from '@/crm/store/actionType';
import { dates, httpRequest, updateBreadcrumb } from '@sales-i/utils';


function createCurrentlyEditing(interaction) {
  let dateTime = new Date(interaction.date_start);
  let dateEnd = new Date(interaction.date_end);
  let duration = dates.getDuration(dateTime, dateEnd);
  if (interaction.is_personal) {
    return {
      dateTime,
      duration,
      entityType: false,
      entity: {},
      contact: {},
      opportunities: [],
      furtherDetails: {
        interactionNote: interaction.description,
      },
      backdated: false,
    };
  } else {
    let { customers, prospects, contacts, opportunities } = interaction.relationships;
    let entityType = customers.length > 0 ? 'customer' : 'prospect';
    let entity = entityType === 'customer' ? {
      ...customers[0],
      header: customers[0] ? customers[0].name : '',
      subHeader: customers[0] ? customers[0].account_number : '',
    } : {
      ...prospects[0],
      header: prospects[0] ? prospects[0].name : '',
      subHeader: prospects[0] ? prospects[0].account_number : '',
    };
    let contact = contacts.length > 0 ? {
      ...contacts[0],
      header: contacts[0] ? contacts[0].full_name : '',
      subHeader: contacts[0] ? contacts[0].relationship.name : '',
    } : {};

    return {
      dateTime,
      duration,
      entityType,
      entity,
      contact,
      opportunities,
      furtherDetails: {
        idealOutcome: interaction.ideal_outcome_id,
        outcome: interaction.outcome_id,
        purpose: interaction.purpose_id,
        nextAction: interaction.next_action_id,
        type: interaction.interaction_type,
        interactionNote: interaction.description,
        interactionType: interaction.interaction_type,
        assignee: interaction.user_id,
      },
    };
  }
}

export const getByIdGetters = {
  item: state => {
    let { data } = state.selected;
    let purpose = data.purpose_name;
    let type = data.interaction_type_name;
    let idealOutcome = data.ideal_outcome_name;
    let outcome = data.outcome_name;
    let nextAction = data.next_action_name;
    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    let dateTime = new Date(data.date_start);
    let dateEnd = new Date(data.date_end);
    let duration = dates.getDuration(dateTime, dateEnd);
    let durationStr = duration.hours ? `${duration.hours}h ` : '';
    durationStr += duration.minutes ? `${duration.minutes}m` : '';
    let hrs = dateTime.getHours();
    let mins = dateTime.getMinutes();
    hrs = dates.addZero(hrs);
    mins = dates.addZero(mins);
    let date = '';
    if (dateTime >= today) {
      let timeDifference = dateTime.getTime() - today.getTime();
      let differenceInDays = timeDifference/(1000*60*60*24);
      if (differenceInDays >= 7) {
        date = dateTime.toLocaleDateString();
      } else {
        if (differenceInDays < 1) {
          date = 'Today';
        } else if (differenceInDays >= 1 && differenceInDays < 2) {
          date = 'Tomorrow';
        } else {
          let daysInWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
          date = daysInWeek[dateTime.getDay()];
        }
      }
    } else {
      date = dateTime.toLocaleDateString();
    }
    let entityType = '';
    let entity = {};
    let contact = {};
    let opportunities = [];
    if (data.relationships) {
      entityType = data.relationships.customers[0] ?
        'customer' : 'prospect';
      entity = entityType === 'customer' ?
        data.relationships.customers[0] : data.relationships.prospects[0];
      contact = data.relationships.contacts[0] ? data.relationships.contacts[0] : {};
      opportunities = data.relationships.opportunities;
    }
    let assignee = data.user_id;
    // translate
    updateBreadcrumb('interactions', data.id, `${purpose || type}: ${date} at ${hrs}:${mins}`);

    return {
      id: data.id,
      time: `${hrs}:${mins}`,
      date: `${date} at `,
      duration,
      durationStr,
      purpose,
      type,
      outcome,
      idealOutcome,
      nextAction,
      description: data.description,
      entityType,
      entity,
      contact,
      opportunities,
      assignee,
    };
  }
};

// mutations
export const getByIdMutations = {
  [GET_BY_ID]: (state, {data, currentlyEditing}) => {
    state.currentlyEditing = currentlyEditing;
    state.selected.data = data;
    state.selected.loaded = true;
    state.selected.loading = false;
    state.selected.error = undefined;
    state.selected.updated = false;
  },
  [GET_BY_ID_LOADING]: (state) => {
    state.selected.loaded = false;
    state.selected.loading = true;
  },
  [GET_BY_ID_ERROR]: (state, error) => {
    state.selected.loaded = false;
    state.selected.loading = false;
    state.selected.error = error;
  },
  [CLEAR_BY_ID]: (state) => {
    state.selected.data = {};
    state.selected.loaded = false;
    state.selected.loading = false;
  },
};

// actions
export const getByIdActions = {
  [GET_BY_ID]: async ({ commit }, parameters) => {
    try {
      const { id, options = {version: '2'} } = parameters;
      commit(CLEAR_BY_ID);
      commit(GET_BY_ID_LOADING);

      let data = await httpRequest('get', `interactions/${id}`, {}, options);
      let currentlyEditing = createCurrentlyEditing(data);
      commit(GET_BY_ID, {data, currentlyEditing});
      return data;
    } catch (error) {
      console.error(error);
      commit(GET_BY_ID_ERROR, error);
      return false;
    }
  },
  [CLEAR_BY_ID]: ({ commit, }) => {
    commit(CLEAR_BY_ID);
    commit(CLEAR_CURRENTLY_EDITING);
  },
};
