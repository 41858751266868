
import { computed, ref } from 'vue';
import { CLEAR_CURRENT_TARGET, ENABLE_TARGETS, FETCH_REPORT_DATA, SAVE_CURRENT_DIMENSIONS, SAVE_CURRENT_VALUES, SAVE_WHOLE_DIM, TOGGLE_TARGETS_SWITCH, } from '@/intelligence/store/actionType';
import { useStore } from 'vuex';
import { GET_BY_ID } from '@/shared/store/actionType';

export default function useTargets({ store = useStore() } = {}) {
  const daysFromInterval = {
    WEEKLY: 7,
    MONTHLY: 30,
    QUARTERLY: 90,
    YEARLY: 365
  };
  const targets = ref([]);
  const isTargetsSwitchActive = computed(() => store.state.intelligence.targets.isTargetsSwitchActive);
  const areTargetsEnabled = computed(() => store.state.intelligence.targets.areTargetsEnabled);  
  const currentTargetValues = computed(() => store.state.intelligence.targets.currentTargetValues);
  const valid = computed(() => currentTargetValues.value.length && currentTargetValues.value.every(item => item.values.length || item.checkedAll));
  const measurements = computed(() => store.state.intelligence.targets.measurementsData);
  const targetsData = computed(() => store.state.intelligence.targets.targetsData || { targets: [] });
  const formatTargetPeriod = computed(() => {
    return (period) => {
      if (!period) return '';
      return `${period.interval.charAt(0) + period.interval.slice(1).toLowerCase()} at ${period.expires}`;
    };
  });

  const getTarget = id => store.dispatch(`intelligence/targets/${GET_BY_ID}`, id);
  const toggleTargetsSwitch = (params) => store.dispatch(`intelligence/targets/${TOGGLE_TARGETS_SWITCH}`, params);
  const enableTargets = value => store.dispatch(`intelligence/targets/${ENABLE_TARGETS}`, value);
  const clearCurrentTarget = () => store.dispatch(`intelligence/targets/${CLEAR_CURRENT_TARGET}`);
  const queryTargetData = payload => store.dispatch(`intelligence/targets/${FETCH_REPORT_DATA}`, payload);
  const saveCurrentValues = payload => store.dispatch(`intelligence/targets/${SAVE_CURRENT_VALUES}`, payload);
  const saveWholeDim = payload => store.dispatch(`intelligence/targets/${SAVE_WHOLE_DIM}`, payload);
  const saveCurrentDims = payload => store.dispatch(`intelligence/targets/${SAVE_CURRENT_DIMENSIONS}`, payload);
  const deleteTarget = payload => store.dispatch(`intelligence/targets/${FETCH_REPORT_DATA}`, payload);

  // helpers
  const metricOptions = computed(() => measurements.value.length ? measurements.value.map(el => (
    { unit: el.unit, text: el.metric, value: el.id }
  )) : []);

  async function getAllTargets() {
    const response = await queryTargetData({
      type: 'get',
      params: {
        limit: 50,
        offset: 0,
      }
    });
    targets.value = response?.targets;
  }

  return {
    targetsData,
    currentTargetValues,
    isTargetsSwitchActive,
    areTargetsEnabled,
    valid,
    measurements,
    metricOptions,
    getTarget,
    toggleTargetsSwitch,
    enableTargets,
    clearCurrentTarget,
    queryTargetData,
    saveCurrentValues,
    saveWholeDim,
    saveCurrentDims,
    deleteTarget,
    daysFromInterval,
    formatTargetPeriod,
    targets,
    getAllTargets,
  };
}