<template>
  <hr>
  <div class="btn-container flex my-2">
    <CustomButton
      purpose="transparent"
      :icon-name="showFields ? 'chevron-down' : 'chevron-right'"
      icon-color="var(--colour-utility-action)"
      :icon-height="32"
      :icon-width="32"
      @click="toggleFields"
    >
      {{ t('analysis_fields') }}
    </CustomButton>
    <div
      v-if="showFields && !isCustomerView()"
      class="edit-buttons flex"
    >
      <CustomButton
        purpose="transparent"
        @click="toggleEdit"
      >
        {{ !isEditing ? t('edit') : t('discard_changes') }}
      </CustomButton>
      <CustomButton
        v-if="isEditing"
        purpose="transparent"
        @click="saveAnalysisFields"
      >
        {{ t('save') }}
      </CustomButton>
    </div>
  </div>
  <div
    v-if="showFields"
    class="my-2"
  >
    <div
      v-for="(field, index) in selectedFields"
      :key="field.id"
      class="field text-black my-1 text-5 flex search-analysis-field"
    >
      <span>{{ analysisFields[index].bubble_label }}</span>: <span v-if="!isEditing">{{ field.description }}</span>
      <SearchField
        v-if="isEditing"
        :key="field.id"
        ref="searchFieldRef"
        class="prospect-search-input"
        :search-func="(q) => searchResultsHandler({ id: field.analysis_field_id, q })"
        :search-on-load="false"
        :placeholder="field.description"
        :results="componentResults"
        :value="field.description"
        :show-all-results="true"
        required
        @close="handleClose"
        @on-close="handleCancel"
      >
        <template #result="{ result }">
          <li
            role="button"
            tabindex="0"
            @click="handleResultClick(result, field, index)"
            @keydown.enter="handleResultClick(result, field, index)"
          >
            {{ result.description }}
          </li>
        </template>
        
        <ul>
          <li
            v-for="result in componentResults" 
            :key="result.id"
          >
            {{ result.description }}
          </li>
        </ul>
      </SearchField>
    </div>
  </div>
  <hr>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { tCrm as t } from '@sales-i/utils';
import { isCustomerView } from '@/shared/utils/urlCheckers';
import { CustomButton } from '@sales-i/dsv3';
import SearchField from '@/shared/components/Search/SearchField.vue';
import { useProspect } from '@/crm/composables/useProspect';

const { searchAnalysisFields, fetchAnalysisFields, putAnalysisFieldsById } = useProspect();

const showFields = ref(false);
const isEditing = ref(false);
const fetchedAnalysisFields  = ref([]);
const searchFieldRef = ref(null);
const componentResults = ref([]);
const selectedFields = ref([]); // Array with all changes for BE request (PUT)

const props = defineProps({
  analysisFields: {
    type: Array,
    default: () => []
  },
  prospectId: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['results']);

onMounted(async () => {
  const data = await fetchAnalysisFields();
  if (data) fetchedAnalysisFields.value = data;

  selectedFields.value = processedAnalysisFields.value.map((field) => ({
    analysis_field_id: field.analysis_field_id,
    analysis_field_lookup_id: field.id,
    code: field.code,
    description: field.description,
  }));
});

const processedAnalysisFields = computed(() => {
  return props.analysisFields.map((propField) => {
    const matchedField = fetchedAnalysisFields.value.find(
      (fetchedField) =>
        fetchedField.bubble_label === propField.bubble_label
    );

    return {
      ...propField,
      analysis_field_id: matchedField ? matchedField.id : null,
    };
  });
});

const toggleFields = () => {
  showFields.value = !showFields.value;
};

const toggleEdit = () => {
  isEditing.value = !isEditing.value;
};

const searchResultsHandler = async ({ id, q }) => {
  try {
    const results = await searchAnalysisFields({ id, q });
    emit('results', results);
    componentResults.value = results;
    return results;
  } catch (error) {
    console.error('Error fetching search results:', error);
  }
};

const handleClose = () => {
  searchFieldRef.value?.closeDropdown?.();
};

const handleCancel = () => {
  searchFieldRef.value?.closeDropdown?.();
};

const handleResultClick = (result, field, index) => {
  const fieldIndex = selectedFields.value.findIndex((item) => item.code === field.code);
  if (fieldIndex > -1) {
    selectedFields.value[fieldIndex] = {
      analysis_field_lookup_id: result.id,
      analysis_field_id: field.analysis_field_id,
      code: field.code,
      description: result.description,
    };
  }
  // Close the dropdown
  searchFieldRef.value[index]?.closeDropdown?.();
};

const saveAnalysisFields = async () => {
  const transformedFields = selectedFields.value.map(field => ({
    analysis_field_lookup_id: field.analysis_field_lookup_id,
    analysis_field_id: field.analysis_field_id,
  }));

  const result = await putAnalysisFieldsById(props.prospectId, transformedFields);
  if (result) isEditing.value = false;
};
</script>

<style lang="scss" scoped>

.search-analysis-field {
  align-items: baseline;
}

.btn-container  {
  justify-content: space-between;

  button {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-semibold);
    text-decoration: underline;
    padding: initial;

  &:deep(.button-container) {
    align-items: center;
    }
  }
}

.field {
  margin: var(--spacing-half) 0;
  gap: var(--spacing-half);
  white-space: nowrap;
}

.prospect-search-input {
  padding-left: var(--spacing-1);
  margin-bottom: 0;
  margin-left: auto;

  :deep(.icon-search) {
    display: none;
  }

  &.active {
    z-index: 100;
  }

  .search-prospects {
    padding: var(--spacing-2);
    margin-bottom: 0;

    &:deep(.c-card) {
      margin-bottom: 0;
    }

    div {
      background-color: var(--colour-utility-white);
    }
  }

  &:deep(.results) {
    overflow: initial;
    max-height: initial;

    ul {
      max-height: 300px;
      overflow-y: auto;
    }

    li {
      padding: var(--spacing-1) var(--spacing-2);
    }
  }

  &:deep(.input) {
    border-radius: var(--border-radius-half);

    .close {
      display: none;
    }

    input {
      font-weight: var(--font-weight-regular);
      padding: var(--spacing-half);
    }
  }
}

.dropdown-container {
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 2;
}
</style>