<template>
  <div
    class="full-picture"
    :class="{ 'iframe-height': iframe }"
  >
    <BufferImage
      v-if="!isReportEnabled"
      color="black"
      float="center"
      class="loading-spinner"
    />
    <section
      v-else
      class="data-section"
    >
      <TableWrapper
        v-if="dataRows?.length"
        table-height="510px"
        :offset-y="offset"
        enable-lazy-load
        @set-offset="loadMoreData"
      >
        <thead>
          <tr>
            <th
              v-for="(heading, index) in columnHeadings"
              :key="index"
              :title="heading"
              :class="{
                sort: sortAttribute === translateHeadingLabelToKey(heading),
                dimension: dimensions.includes(heading),
                clickable: index,
                hidden: (fragileData.includes(heading) && isCustomerModeEnabled) || (!dimensions.includes(heading) && props.hideValueFields),
              }"
              @click="changeSorting(heading)"
            >
              <TableHeadContent v-if="sortAttribute === translateHeadingLabelToKey(heading)">
                <template #icon>
                  <IconBase
                    :width="16"
                    :height="16"
                    :icon-name="sortOrderIconName"
                    icon-color="var(--colour-utility-black)"
                  />
                </template>
                <div class="heading">
                  {{ capitalize(heading, { onlyFirstLetter: true }) }}
                </div>
              </TableHeadContent>

              <template v-else>
                {{ capitalize(heading, { onlyFirstLetter: true }) }}
              </template>

              <Field
                v-if="!index"
                v-model="searchRowsQuery"
                class="search-container"
                type="text"
                :icon="{
                  name: 'search',
                  size: 24,
                }"
              />
              <div
                v-else-if="!dimensions.includes(heading)"
                class="chip"
                :title="getChipValue(heading)"
              >
                {{ getChipValue(heading) }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(dataRow, index) in dataRows"
            :id="dataRow.Id"
            :key="index"
            :class="{ 'tr-hidden': !showRow(dataRow), 'target-active': isTargetsSwitchActive }"
          >
            <td
              :class="{ 'drill-row': isDrillActive }"
              class="row-title"
              :title="dataRow.dimensions[0].dimensions[0]"
            >
              <CustomButton
                v-if="dataRow.checked && isDrillActive && !isSmallScreen"
                class="hover-btn"
                action="submit"
                :label="t('Next')"
                @click="toggleSelectBubblesPopup"
              />
              <div
                v-if="isDrillActive"
                class="drill"
              >
                <CustomCheckbox
                  v-show="drillEnabled"
                  :id="dataRow.Id"
                  class="checkbox"
                  :value="dataRow.checked"
                  @input="handleEnquiryDrill(dataRow, $event, index)"
                />
              </div>
              <div class="title">
                <section
                  v-if="dataRow.dimensions[0].entity_id && !iframe"
                  class="grid-container"
                >
                  <span class="order-number-link">#{{ index + 1 }}&nbsp;</span>
                  <a
                    class="bubble-title link order-left-aligned"
                    :href="getCustomerLink(dataRow.dimensions[0].entity_id)"
                    target="_blank"
                  >
                    {{ dataRow.dimensions[0].dimensions[0] }}
                  </a>
                  <p class="bubble-subtitle order-left-aligned">
                    {{ dataRow.dimensions[0].dimensions[1] }}
                  </p>
                </section>
                <section
                  v-else
                  class="grid-container"
                >
                  <span class="order-number">#{{ index + 1 }}&nbsp;</span>
                  <p class="bubble-title order-left-aligned">
                    {{ dataRow.dimensions[0].dimensions[0] }}
                  </p>
                  <p class="bubble-subtitle order-left-aligned">
                    {{ dataRow.dimensions[0].dimensions[1] }}
                  </p>
                </section>
              </div>
            </td>
            <td
              v-if="dataRow.dimensions[1]"
              :title="dataRow.dimensions[1].dimensions[0]"
              class="row-title"
            >
              <div v-if="dataRow.dimensions[1].entity_id && !iframe">
                <a
                  class="bubble-title link"
                  :href="getCustomerLink(dataRow.dimensions[1].entity_id)"
                  target="_blank"
                >
                  <span>{{ dataRow.dimensions[1].dimensions[0] }}</span>
                </a>
                <p class="bubble-subtitle">
                  {{ dataRow.dimensions[1].dimensions[1] }}
                </p>
              </div>
              <div v-else>
                <p>
                  {{ dataRow.dimensions[1].dimensions[0] }}
                </p>
                <p class="bubble-subtitle">
                  {{ dataRow.dimensions[1].dimensions[1] }}
                </p>
              </div>
            </td>
            <td
              v-for="dim in getAdditionalDimensions(dataRow)"
              :key="dim"
            >
              {{ dimensionalFormat(dim.dimensions[0]) }}
            </td>
            <td
              v-show="!props.hideValueFields"
              :class="{ negative: dataRow.sales_value < 0 }"
              :title="dataRow.sales_value"
            >
              {{ cPrice(dataRow.sales_value) }}
              <TargetsChip
                v-if="isTargetsSwitchActive && dataRow.sales_value_target"
                :target-values="dataRow.sales_value_target"
                :target-info="targetInfo"
              />
            </td>
            <td
              v-show="!isCustomerModeEnabled && !props.hideValueFields"
              :class="{ negative: dataRow.cost < 0 }"
              :title="dataRow.cost"
            >
              {{ cPrice(dataRow.cost) }}
            </td>
            <td
              v-show="!isCustomerModeEnabled && !props.hideValueFields"
              :class="{ negative: dataRow.profit < 0 }"
              :title="dataRow.profit"
            >
              {{ cPrice(dataRow.profit) }}
              <TargetsChip
                v-if="isTargetsSwitchActive && dataRow.profit_target"
                :target-values="dataRow.profit_target"
                :target-info="targetInfo"
              />
            </td>
            <td
              v-show="!isCustomerModeEnabled && !props.hideValueFields"
              :class="{ negative: dataRow.gross_profit < 0 }"
              :title="`${dataRow.gross_profit}%`"
            >
              {{ abbr.float(dataRow.gross_profit) + '%' }}
            </td>
            <td
              v-show="!props.hideValueFields"
              :class="{ negative: dataRow.average_price < 0 }"
              :title="dataRow.average_price"
            >
              {{ cPrice(dataRow.average_price) }}
            </td>
            <td
              v-show="!props.hideValueFields"
              :class="{ negative: dataRow.quantity < 0 }"
              :title="dataRow.quantity"
            >
              {{ cNumber(dataRow.quantity, 0) }}
              <TargetsChip
                v-if="isTargetsSwitchActive && dataRow.quantity_target"
                :target-values="dataRow.quantity_target"
                :target-info="targetInfo"
              />
            </td>
            <td
              v-show="!props.hideValueFields"
              :class="{ negative: dataRow.count < 0 }"
              :title="dataRow.count"
            >
              {{ cNumber(dataRow.count) }}
            </td>
            <td
              v-show="!props.hideValueFields"
              :class="{ negative: dataRow.invoice_count < 0 }"
              :title="dataRow.invoice_count"
            >
              {{ cNumber(dataRow.invoice_count) }}
            </td>
            <td
              v-show="!props.hideValueFields"
              :class="{ negative: dataRow.average_items_per_invoice < 0 }"
              :title="dataRow.average_items_per_invoice"
            >
              {{ cNumber(dataRow.average_items_per_invoice) }}
            </td>
          </tr>
        </tbody>
      </TableWrapper>
      <NoResults v-if="(!dataRows?.length) && isReportEnabled" />
    </section>
    <ReportFooter
      v-if="isReportFooterVisible"
      :dimensions="dimensions"
      :report-type="reportType"
      :sub-title="title"
    />
  </div>
</template>

<script setup>
// TODO: replace search field with SearchInput
// TODO: TO decide: use Fuse for search or remove Fuse from the project
import { ref, computed, watch, onMounted } from 'vue';
import { useMq } from 'vue3-mq';
import { useStore } from 'vuex';
import breakpoints from '@/shared/utils/breakpoints';
import { abbr, dates, t } from '@sales-i/utils';
import { capitalize } from '@/shared/utils/strings';
import { BufferImage, CustomCheckbox, IconBase, CustomButton } from '@sales-i/dsv3';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import NoResults from '@/intelligence/components/Shared/NoResults';
import TargetsChip from '@/intelligence/components/FullPicture/TargetsChip.vue';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import TableHeadContent from '@/shared/components/Tables/TableHeadContent';
import { REPORTS_SORT_ASCENDING_KEY, REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';
import { FULL_PICTURE, QUERY_BUILDER } from '@/intelligence/store/data/reportTypes';
import { REPORTS_KPI_SALES_VALUE } from '@/intelligence/store/data/kpis';
import Field from '@/shared/components/Form/Field';
import { baseUrl as crmBaseUrl, customersArea, prospectsArea } from '@/crm/router/urlBits';
import useCurrency from '@/shared/composables/useCurrency';
import usePermissions from '@/shared/composables/usePermissions';
import { intelligence_enquiries } from '@/shared/store/data/policies';
import isRolldate from '@/intelligence/store/utils/isRolldate';
import useShared from '@/intelligence/composables/useShared';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import useCalendar from '@/intelligence/composables/useCalendar';
import useTargets from '@/intelligence/composables/useTargets';
import useSystem from '@/shared/composables/useSystem';

const store = useStore();
const mq = useMq();
const { runIfPermittedOrReject } = usePermissions();
const { cPrice, cNumber } = useCurrency();
const { 
  isDrillActive, drillActiveRows, requestParameters,
  getDrillsLevel, getDrillingDimensionId,
  getReportData, fetchReportData, applyDate, applyOffset, applyLimit, toggleDrillOption,
  toggleSelectBubblesPopup,
} = useShared({ store });
const { setSortingData } = useEnquiries({ store });
const { fetchDates, fetchedDates } = useCalendar({ store });
const { enableTargets, isTargetsSwitchActive } = useTargets({ store });
const { iframe } = useSystem({ store });

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  dateParams: {
    type: Object,
    required: true,
  },
  isReportEnabled: {
    type: Boolean,
    default: false,
  },
  reportType: {
    type: String,
    default: FULL_PICTURE
  },
  searchMode: {
    type: String,
    default: 'customer',
  },
  hideValueFields: {
    type: Boolean,
    default: false,
  },
  drillEnabled: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['handleEnquiryDrill', 'loaded', 'loading']);

const dataRows = ref([]);
const offset = ref(0);
const sortAttribute = ref(REPORTS_KPI_SALES_VALUE);
const sortMethod = ref(REPORTS_SORT_DESCENDING_KEY);
const fragileData = ref(['Cost', 'Profit', 'Gross Profit']);
const searchRowsQuery = ref('');
const fullPictureId = ref(1);
const lazyLoadingData = ref([]);
const targetInfo = ref(null);

const isCustomerModeEnabledInState = computed(() => store.state.system.isCustomerModeEnabled);

// CustomerMode is can be enabled manually, or for some tenants. To distinguish those tenants only option is to check if there are empty headings (Profit, Cost, Gross Profit are removed)
const isCustomerModeEnabled = computed(() => isCustomerModeEnabledInState.value || shouldHideFragileData.value);
const shouldHideFragileData = computed(() => {
  const currentHeadings = enquiryData.value.headings.values;
  return currentHeadings.some(heading => heading === '');
});

const enquiryData = computed(() => {
  const reportData = { ...getReportData(fullPictureId.value) } || null;
  if (lazyLoadingData.value.length && reportData) {
    for (const iteration of lazyLoadingData.value) {
      const { row_count, rows } = iteration;
      reportData.row_count += row_count;
      reportData.rows = reportData.rows.concat(rows);
    }
  }
  return reportData;
});

const sortOrderIconName = computed(() => (sortMethod.value === REPORTS_SORT_ASCENDING_KEY ? 'arrow-up' : 'arrow-down'));
const isSmallScreen = computed(() => breakpoints.smAndDown.includes(mq.current));
const isReportFooterVisible = computed(
  () => props.isReportEnabled && enquiryData.value && 'rows' in enquiryData.value && enquiryData.value.rows.length && !iframe.value
);
const summaryData = computed(() => enquiryData.value && enquiryData.value.summary);
const dimensions = computed(() => enquiryData.value && enquiryData.value.headings.dimensions);
const columnHeadings = computed(() => {
  if (!enquiryData.value) return [];
  return [...dimensions.value, ...enquiryData.value.headings.values.filter(Boolean)]; // Filter to remove empty headings if there are any (see. isCustomerModeEnabled)
});

// WATCHERS
watch(enquiryData, newData => {
  dataRows.value = processRows(newData.rows);
  checkDrillActiveRows();
});
watch(drillActiveRows, (newRows, oldRows) => {
  if (!newRows.length) {
    oldRows.forEach(oldRow => {
      handleEnquiryDrill(findRowByDrillValue(oldRow), false);
    });
  }
});
watch(() => props.isReportEnabled, (newVal) => {
  // This should be triggered only in QUERY_BUILDER report, to avoid doubeled request in FP
  if (newVal === false && props.reportType === QUERY_BUILDER) {
    updateEnquiryData(true);
  }
});

onMounted(() => {
  applyOffset(0);
  applyLimit(100);
  updateEnquiryData(true);
  if (!isSmallScreen.value) toggleDrillOption(true);
});

function guessDataType(input) {
  if (input.length >= 6 && dates.format(input) !== '') {
    return 'date';
  }
  return 'string';
}

function dimensionalFormat(dimension) {
  switch (guessDataType(dimension)) {
  case 'date':
    return dates.format(dimension);
  default:
    return dimension;
  }
}

function findRowByDrillValue(drillObject) {
  return dataRows.value && dataRows.value.find(row => row.id === drillObject.id);
}

function getChipValue(heading) {
  const newSummaryData = summaryData.value[translateHeadingLabelToKey(heading)];
  switch (heading) {
  case 'Gross Profit':
    return `${abbr.float(newSummaryData)}%`;
  case 'Quantity':
  case 'Count':
  case 'Invoice Count':
  case 'Avg. Items Per Invoice':
    return `${cNumber(newSummaryData, 0)}`;
  case 'Avg. Price':
  case 'Sales Value':
  case 'Cost':
  case 'Profit':
    return cPrice(newSummaryData);
  default:
    return newSummaryData;
  }
}

function translateHeadingLabelToKey(label) {
  if (!label) {
    return '';
  } else if (dimensions.value.indexOf(label) === 1) {
    return 'dim2';
  } else if (label === 'Avg. Price') {
    return 'average_price';
  } else if (label === 'Avg. Items Per Invoice') {
    return 'average_items_per_invoice';
  }
  return label.toLowerCase().replace(' ', '_');
}

function changeSorting(newSortAttribute) {
  const newSortKey = translateHeadingLabelToKey(newSortAttribute);
  // if new sort attribute is a dimension name we prevent it
  if (dimensions.value.includes(newSortAttribute) && dimensions.value[1] !== newSortAttribute) {
    return;
  }
  if (newSortKey === sortAttribute.value) {
    // SAME ATTRIBUTE/HEADER and DIFFERENT METHOD/OPTION
    sortMethod.value =
      sortMethod.value === REPORTS_SORT_DESCENDING_KEY ? REPORTS_SORT_ASCENDING_KEY : REPORTS_SORT_DESCENDING_KEY;
  } else {
    // DIFFERENT ATTRIBUTE/HEADER and SAME METHOD/OPTION
    sortAttribute.value = newSortKey;
    sortMethod.value = REPORTS_SORT_DESCENDING_KEY;
  }
  setSortingData([sortAttribute.value, sortMethod.value]);
  offset.value = 0;
  applyOffset(offset.value);
  updateEnquiryData(true);
}

async function updateEnquiryData(completeRefresh = false) {
  runIfPermittedOrReject(intelligence_enquiries, async () => {
    emit('loading');
    if (isRolldate(props.dateParams)) {
      await fetchDates(props.dateParams);
      applyDate(fetchedDates.value);
    } else {
      applyDate(props.dateParams);
    }
    setSortingData([sortAttribute.value, sortMethod.value]);
    lazyLoadingData.value = [];
    fullPictureId.value = await fetchReportData({
      reportType: props.reportType,
      completeRefresh,
    });
    dataRows.value = processRows(enquiryData.value.rows);
    checkDrillActiveRows();

    if (enquiryData.value && enquiryData.value.targets_exist) {
      targetInfo.value = enquiryData.value.target_info;
      enableTargets(true);
    } else {
      enableTargets(false);
    }
    emit('loaded');
  });
}

function processRows(rows) {
  return (
    rows &&
    rows.map(row => {
      const rowDims = row.dimensions;
      let id = `${rowDims[0].dimensions[0]},${rowDims[0].dimensions[1]}`;
      if (row.dimensions.length > 1) id += `:${rowDims[1].dimensions[0]},${rowDims[1].dimensions[1]}`;
      return { ...row, checked: false, id };
    })
  );
}

function checkDrillActiveRows() {
  drillActiveRows.value.forEach(activeRow => {
    const row = findRowByDrillValue(activeRow);
    if (row) row.checked = true;
  });
}

function handleEnquiryDrill(dataRow, checked, index) {
  if (!dataRow) return;
  if (index === undefined) {
    dataRows.value = dataRows.value.map(row => {
      return {
        ...row,
        checked,
      };
    });
  } else {
    dataRows.value[index].checked = checked;
  }
  let id, item;
  const drillLvl = getDrillsLevel();
  const dim1Id = getDrillingDimensionId(drillLvl, 0);
  const dim2Id = getDrillingDimensionId(drillLvl, 1);
  const newDimensions = dataRow.dimensions;
  switch (newDimensions.length) {
  case 1:
    id = `${newDimensions[0].dimensions[0]},${newDimensions[0].dimensions[1]}`;
    item = {
      [dim1Id]: {
        value: newDimensions[0].dimensions[0],
        pair: newDimensions[0].dimensions[1],
      },
    };
    break;
  case 2:
  default:
    id = `${newDimensions[0].dimensions[0]},${newDimensions[0].dimensions[1]}:${newDimensions[1].dimensions[0]},${newDimensions[1].dimensions[1]}`;
    item = {
      [dim1Id]: {
        value: newDimensions[0].dimensions[0],
        pair: newDimensions[0].dimensions[1],
      },
      [dim2Id]: {
        value: newDimensions[1].dimensions[0],
        pair: newDimensions[1].dimensions[1],
      },
    };
    break;
  }
  emit('handleEnquiryDrill', {
    checked,
    id,
    item,
  });
}

async function loadMoreData() {
  if (offset.value + requestParameters.value.limit > dataRows.value.length) return;
  offset.value += requestParameters.value.limit;
  applyOffset(offset.value);
  const lazyLoadId = await fetchReportData({ reportType: props.reportType });
  lazyLoadingData.value.push(getReportData(lazyLoadId));
}

function getCustomerLink(id) {
  return `${crmBaseUrl}/${props.searchMode === 'prospect' ? prospectsArea : customersArea}/${id}`;
}

function showRow(row) {
  const query = searchRowsQuery.value.trim().toLowerCase();
  return query.length < 1 || (typeof row.id === 'string' && row.id.toLowerCase().includes(query));
}

function getAdditionalDimensions(dataRow) {
  return dataRow.dimensions.slice(2);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.full-picture {
  width: 100%;
  margin: 0 auto auto;
  position: relative;
  background: var(--colour-utility-white);
  display: flex;
  flex-direction: column;
  min-height: 580px;

  .data-section {
    border-radius: var(--border-radius-1);
    box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
    flex: 1;
    display: flex;
    position: relative;
    overflow: hidden;
    margin-bottom: 58px;
    flex-wrap: wrap;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-bottom: 0;
    }
  }

  &.iframe-height {
    min-height: 510px;
  }
}

.header-section {
  position: relative;
  padding: var(--spacing-4) var(--spacing-4) var(--spacing-1);

  h3 {
    text-align: center;
    padding: 0 var(--spacing-2);
    font-weight: var(--font-weight-semibold);

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      padding: 0 var(--spacing-4);
    }
  }

  span {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-small);
    display: block;
    text-align: center;
    color: var(--colour-utility-base);
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

table {
  thead {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 15;
  }

  tbody {
    margin-top: 73px;
  }

  td,
  th {
    vertical-align: middle;
    font-size: var(--font-size-body);
    color: var(--colour-utility-black);
    text-align: center;
    border: 0;
    position: relative;
  }

  td {
    font-size: var(--font-size-small);
    padding: var(--spacing-1) 0;
  }

  thead {
    th {
      min-width: 120px;
      padding: var(--spacing-2) var(--spacing-2) var(--spacing-4);
      background: var(--colour-panel-action);
      color: var(--colour-utility-action);
      border-bottom: 1px solid var(--colour-panel-g-16);
      position: relative;
      top: 0;
      z-index: 1;
      text-decoration: underline;

      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        min-width: 128px;
      }

      .heading {
        overflow: visible;
        white-space: nowrap;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 24px;
      }

      .svg-container {
        position: absolute;
        top: 0;
        right: 0;
      }
      &.dimension {
        min-width: 120px;

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
          min-width: 195px;
        }
      }

      &.sort {
        background-color: var(--colour-panel-g-2);
        padding-top: var(--spacing-2);
        padding-bottom: var(--spacing-5);
      }

      &.clickable:hover {
        cursor: pointer;
        background-color: var(--colour-data-deluge-light);
      }
    }
  }

  tbody {
    tr {
      .drill ~ .title {
        padding-left: var(--spacing-3);
      }

      &.tr-hidden {
        display: none;
      }

      &:not(.tr-hidden) {
        th,
        td {
          padding-top: var(--spacing-3);
          .drill {
            align-items: flex-end;
            padding-top: var(--spacing-3);
          }
        }
        & ~ tr:not(.tr-hidden) {
          th,
          td {
            padding-top: var(--spacing-1);
            .drill {
              align-items: center;
              padding-top: var(--spacing-2);
            }
          }
        }
      }
    }
  }
  tr {
    td {
      &.row-title {
        // text-align: left;
        padding-left: var(--spacing-2);
        cursor: help;
        background: var(--colour-panel-g-2);

        .bubble-title.link {
          font-size: var(--font-size-5);
          font-weight: var(--font-weight-semibold);
          display: inline-flex;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 160px;
          display: inline-block;
        }

        .bubble-subtitle {
          color: var(--colour-utility-base);
        }

        p:not(.bubble-subtitle) {
          font-weight: var(--font-weight-semibold);
        }

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .hover-btn {
          padding: var(--spacing-1) var(--spacing-2);
          left: var(--spacing-5);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          height: 45px;
          width: 100px;
          display: none;
        }

        &:hover .hover-btn {
          display: block;
        }
      }

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
      }
    }

    &:first-child td.row-title .hover-btn {
      top: calc(50% + 10px);
    }
  }
}

.drill {
  background: var(--colour-panel-base);
  padding: var(--spacing-2) var(--spacing-1);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  .checkbox {
    background: var(--colour-utility-white);
    border-radius: var(--border-radius-half);
    margin-bottom: 0;
    width: 1.8em;
    height: 1.8em;
  }
}

.chip {
  position: absolute;
  width: 100px;
  color: var(--colour-utility-black);
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--border-radius-half) var(--shadow-spread) var(--shadow-colour);
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-medium);
  letter-spacing: 0;
  line-height: var(--spacing-3);
  padding: var(--spacing-1);
  border-radius: var(--spacing-6);
  margin: auto;
  margin-top: calc(var(--spacing-half) + var(--spacing-1));
  left: 0;
  right: 0;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 104px;
  }
}

.no-results {
  flex-wrap: wrap;
  width: 100%;

  h3 {
    font-weight: var(--font-weight-semibold);
  }

  h3,
  p {
    text-align: center;
    margin: 0;
  }
}

.negative {
  color: var(--colour-data-mandy-label);
}

.search-container {
  position: absolute;
  z-index: 16;
  left: var(--spacing-2);
  margin-right: var(--spacing-half);
  margin-top: calc(var(--spacing-half) + var(--spacing-1));

  :deep(.form-group) {
    margin: 0;
  }

  .field {
    position: relative;
  }

  :deep(.svg-container.icon-search) {
    top: 50% !important;
    transform: translateY(-50%);
  }

  :deep(input) {
    max-height: 40px;
    border-radius: var(--spacing-4);
    font-weight: var(--font-weight-regular);
    padding: var(--spacing-1) var(--spacing-2) var(--spacing-1) var(--spacing-4);
  }
}

.target-active td {
  padding-bottom: var(--spacing-2);
}

.title {
  .grid-container {
    display: grid;
    grid-template-columns: auto 1fr;

    .order-number {
      font-weight: var(--font-weight-semibold);
    }
    .order-number-link {
      grid-column: 1;
      white-space: nowrap;
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-semibold);
      color: var(--colour-utility-base);
      line-height: inherit;
    }

    .bubble-title,
    .bubble-subtitle {
      grid-column: 2;
      align-self: start;
    }

    .bubble-subtitle {
      margin-left: 0;
    }
  }
}
</style>
