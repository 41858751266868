<template>
  <div
    class="dashboards-nav"
  >
    <div class="list p-2">
      <CustomCard
        v-for="(item, index) in items"
        :key="index"
        purpose="reversed"
        class="item"
        :class="[{ active: selected?.id === item.id }, item.category || undefined]"
        @click="handleOpen(item)"
      >
        <h3>
          {{ item.title }}
        </h3>
        <p class="my-1 description">
          {{ item.description }}
        </p>
        <template
          v-if="isWidgetActionsEnabled(item)"
          #cta
        >
          <CustomButton
            purpose="text"
            class="ml-1 fw-sbld"
            @click="handleEdit(item)"
          >
            {{ t('edit') }}
          </CustomButton>
          <CustomButton
            purpose="text"
            class="ml-1 fw-sbld"
            @click="handleDelete(item)"
          >
            {{ t('delete') }}
          </CustomButton>
        </template>
      </CustomCard>

      <div class="flex align-center mx-2">
        <CustomButton
          class="add-btn"
          icon-name="plus"
          icon-color="var(--colour-utility-white)"
          icon-only
          :icon-height="24"
          :icon-width="24"
          @click="handleCreate"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
// DashboardsNav
import { tCrm as t } from '@sales-i/utils';
import { CustomButton, CustomCard } from '@sales-i/dsv3';
import { OTHER_DASHBOARD_CATEGORY } from '@/intelligence/components/Dashboard/constants';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  selected: {
    type: Object,
    default: () => undefined,
  },
});

const emit = defineEmits(['select', 'create', 'edit', 'delete']);

function isWidgetActionsEnabled(item) {
  // Show edit and delete options for only custom dashboard.
  const isOtherOrTargetsCategory = item.category === OTHER_DASHBOARD_CATEGORY || item.category === 'targets';
  const hasMoreThanTwoOtherItems = props.items.filter(i => i.category === OTHER_DASHBOARD_CATEGORY).length > 2;
  
  return (
    item.id >= 0
    && !item.fixed
    && props.selected?.id === item.id
    && (!isOtherOrTargetsCategory || hasMoreThanTwoOtherItems)
  );
}

function handleOpen(item) {
  emit('select', item);
}
function handleCreate() {
  emit('create');
}
function handleEdit(item) {
  emit('edit', item);
}
function handleDelete(item) {
  emit('delete', item);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.dashboards-nav {
  transition: all 0.5s ease-in-out;
  width: 100%;
}

.list {
  overflow-x: auto;
  background: var(--colour-panel-alert-base);
  display: grid;
  gap: var(--spacing-2);
  min-width: 100%;
  grid-auto-flow: column;
  justify-content: start;

  .item {
    border: 2px solid transparent;
    &.active, &:focus-within:not([disabled]) {
      box-shadow: 0px 0px 0px 4px var(--colour-utility-focus);
      border: 2px solid var(--colour-utility-checked);
      outline: 2px solid transparent;
      :deep(.svg-container) {
        :deep(svg) {
          color: white;
        }
      }
    }

    :deep(.c-card--body) {
      overflow: hidden;
    }
  }
}

.description {
  max-height: 150px;
  overflow: hidden;
}

:deep(.c-card) {
  box-shadow: none;
  border-width: 2px;
  position: relative;
  padding: var(--spacing-2);
  width: 250px;
  
  .card-info {
    margin-top: 0;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-top: var(--flow-space);
    }
  }
}

:deep(.cta) {
  display: flex;
  justify-content: flex-end;
  overflow-y: unset;
  min-height: var(--spacing-3)
}

.custom, .other {
  color: var(--colour-data-deluge-dark);
  border-color: var(--colour-data-deluge-dark);
}
.performance {
  color: var(--colour-data-puerto-rico-dark);
  border-color: var(--colour-data-puerto-rico-dark);
}
.people {
  color: var(--colour-data-puerto-rico-dark);
  border-color: var(--colour-data-puerto-rico-dark);
}
.products {
  color: var(--colour-data-viking-label);
  border-color: var(--colour-data-viking-label);
}
.customer {
  color: var(--colour-data-turmeric-label);
  border-color: var(--colour-data-turmeric-label);
}

.actions {
  max-width: 32px;
}
</style>
